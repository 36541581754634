const { applyBusinessStyles } = require('../index');

const businessStyles = {
  backgroundColor: '#F6F6F6',
  backgroundImageHeight: '100vh',
  headerBackgroundColor: '#FFFFFF',
  headerTextColor: '#555',
  actionColor: '#4271ff',
  accentColor: '#4271ff',
  textColor: '#555',
  cardBackgroundColor: '#FFFFFF',
  listBackgroundColor: '#FFFFFF',
  inputBackgroundColor: '#FFFFFF',
  inputOnCardBackgroundColor: '#FFFFFF',
  buttonBorderRadius: '2px',
  inputBorderRadius: '2px',
  cardBorderRadius: '2px',
  cardTitleSize: '22px',
  displayMenuImagesFullWidth: true,
  inverseButtonBorderWidth: '2px',
  iconPackage: 'default',
  isPizza: false,
  showTabs: false,
  showGifts: false,
  showProfileOnSignup: false,
  showBirthdayOnSignup: true,
  imageItemFlexLayout: 'row',
  orderItemsCardHeight: '128pt',
  hompageTitleCentered: true,
  homepageTileButtons: true,
};

module.exports = applyBusinessStyles(businessStyles);
