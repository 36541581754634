import React from "react";
import { omit } from "lodash";
import { Location } from "@reach/router";
import Layout from "./src/layouts/index";
import SecurityProvider from "./src/components/security-provider";
import { ProvideTargetContext } from "./src/target-context";

export default ({ element, props }) => {
  const { pageContext: { target = 'ordering' } } = props;
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  const {
    location: { pathname },
    pageContext,
  } = props;

  if (
    props.custom404 ||
    element.key === "/404.html" ||
    pathname === "/404.html" ||
    pathname === "/404/"
  ) {
    return element;
  }

  const businessAppConfiguration = pageContext.businessAppConfiguration || {};
  const { useRecaptchaInSignUp } = businessAppConfiguration;
  const { recaptchaKey } = businessAppConfiguration;
  // const layoutData = merge(
  //   ...map(values(get(props, "pageResources.staticQueryResults")), "data"),
  // );
  // console.log(element);
  return (
    <ProvideTargetContext target={target}>
      <Location>
        {({ location }) => (
          <SecurityProvider
            useReacptcha={useRecaptchaInSignUp}
            recaptchaKey={recaptchaKey}
          >
            <Layout {...omit(props, "data")} location={location}>
              {element}
            </Layout>
          </SecurityProvider>
        )}
      </Location>
    </ProvideTargetContext>
  );
};
