import without from "lodash/without";
import uniq from "lodash/uniq";
import concat from "lodash/concat";
import find from "lodash/find";
import intersection from "lodash/intersection";
import findIndex from "lodash/findIndex";
import includes from "lodash/includes";

export default (
  checked,
  {
    batches,
    couponInstances,
    couponsBatchIndex = -1,
    couponsUncheckedByUser: uncheckedByUser = [],
    couponsCheckedByUser: checkedByUser = [],
  },
) => {
  const { couponInstanceIds: selectedByCurrentBatch = [] } =
    couponsBatchIndex > -1 && batches
      ? batches[couponsBatchIndex] || {}
      : { couponInstanceIds: [] };

  let nextUncheckedByUser;
  let nextCheckedByUser;
  // console.log({ selectedByCurrentBatch });
  const newlyChecked = _.without(checked, ...selectedByCurrentBatch);
  console.log({ checked, newlyChecked });
  if (newlyChecked.length) {
    nextUncheckedByUser = _.without(uncheckedByUser, newlyChecked[0]);
    nextCheckedByUser = _.uniq(_.concat(checkedByUser, newlyChecked));
  } else {
    const newlyUnchecked = _.without(selectedByCurrentBatch, ...checked);
    nextCheckedByUser = _.without(checkedByUser, newlyUnchecked[0]);
    nextUncheckedByUser = _.uniq(_.concat(uncheckedByUser, newlyUnchecked));
  }

  const nextBatch = _.find(
    _.filter(
      batches,
      (batch) =>
        _.without(batch.couponInstanceIds, ...nextUncheckedByUser).length ===
        batch.couponInstanceIds.length,
    ),
    (batch) =>
      _.intersection(batch.couponInstanceIds, nextCheckedByUser).length ===
      nextCheckedByUser.length,
  );

  if (!nextBatch && newlyChecked.length) {
    const nextBatchIndex = _.findIndex(batches, (batch) =>
      _.includes(batch.couponInstanceIds, newlyChecked[0]),
    );
    return {
      couponsBatchIndex: nextBatchIndex,
      couponsUncheckedByUser: [],
      couponsCheckedByUser: newlyChecked,
    };
  }
  const nextBatchIndex = nextBatch ? nextBatch.batchIndex : -1;

  return {
    couponsBatchIndex: nextBatchIndex,
    couponsUncheckedByUser: nextUncheckedByUser,
    couponsCheckedByUser: nextCheckedByUser,
  };
};
