import React from "react";
import { Typography } from "@mui/material";
import Button from "../Button";
import ErrorMessage from "../ErrorMessage";
import Modal from "../Modal";
import * as styles from "./index.module.scss";

export default ({
  appStyles,
  T,
  modalOpen,
  onClose,
  onSubmit,
  title,
  body,
  isLoading = false,
  icon = null,
  error = null,
}) => {

  return (
    <Modal
      open={modalOpen}
      onClose={onClose}
      appStyles={appStyles}
      style={{ width: "100%" }}
    >
      <div className={styles.iconContainer}>
        {icon && (
          <div
            style={{ color: appStyles.actionColor }}
            className={styles.SVGWrapper}
          >
            {icon}
          </div>
        )}
        <Typography variant="h5">{T(title)}</Typography>
      </div>
      <Typography align="center" sx={{ mt: 3 }}>
        {T(body)}
      </Typography>
      {error && (
        <ErrorMessage appStyles={appStyles}>
          {T(
            error ||
              "Something went wrong, please try again later"
          )}
        </ErrorMessage>
      )}
      <Button
        centered
        appStyles={appStyles}
        onClick={onSubmit}
        style={{ margin: 20 }}
        loading={isLoading}
      >
        {T("Continue")}
      </Button>
      <Button
        linkStyle
        centered
        appStyles={appStyles}
        onClick={onClose}
        style={{ margin: 20 }}
      >
        {T("Cancel")}
      </Button>
    </Modal>
  );
};
