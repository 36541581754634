const { without, defaultsDeep } = require("lodash");
const internalApi = require("../pre-build/internal-api");

let loadedAppStyles = null;

module.exports.load = async (businessId) => {
  if (!businessId) {
    console.log("Please provide BUSINESS_ID environment variable");
    process.exit(1);
  }

  console.log(`loading styles for business id: ${businessId}`);

  loadedAppStyles =
    loadedAppStyles ||
    ((await internalApi.app.getAppStyles(businessId)) ||
      require(`./businesses/default`));

  return applyBusinessStyles(loadedAppStyles);
};

const Font = (font, size) =>
  font
    ? {
        fontFamily: `${font}, sans-serif`,
        ...(size && { fontSize: Math.round(size) + "px" }),
      }
    : null;

const validateStyles = (appStyles) => {
  const TASTE_PREFERENCES = ["dairyfree", "glutenfree", "vegan", "vegeterian"];

  const unsupportedTastePreferences = without(
    appStyles.tastePrefrencesOptions,
    ...TASTE_PREFERENCES,
  );

  if (unsupportedTastePreferences.length > 0) {
    throw `There following taste preferences: ${unsupportedTastePreferences} are not supported.`;
  }

  return appStyles;
};

const applyBusinessStyles = (
  businessStyles,
  { font, fontSizeMultiplier = 1 } = {},
) =>
  validateStyles(
    defaultsDeep(businessStyles, {
      locale: "en-US",
      disabledTextColor: "#e1e1e1",
      Header: {
        backgroundColor: businessStyles.headerBackgroundColor,
        color: businessStyles.headerTextColor,
        ...Font(font, 20 * fontSizeMultiplier),
      },
      HomeButtonsMobile: {
        ...Font(font, 27 * fontSizeMultiplier),
        ...businessStyles.HomeButtonsMobile,
      },
      HomeButtonsDesktop: {
        ...Font(font, 27 * fontSizeMultiplier),
        ...businessStyles.HomeButtonsDesktop,
      },
      HomeFirstTitle: {
        ...Font(font, 56 * fontSizeMultiplier),
        textTransform: "none",
      },
      HomeSecondTitle: {
        ...Font(font, 56 * fontSizeMultiplier),
        textTransform: "none",
      },
      HomeMainTitleDesktop: {
        ...Font(font, 56 * fontSizeMultiplier),
        fontSize: "2.625rem",
        marginBottom: 20,
        textTransform: "none",
      },
      HomeExtraTitlesDesktop: {
        ...Font(font, 24 * fontSizeMultiplier),
        textTransform: "none",
      },
      PageHeader: { ...Font(font, 30 * fontSizeMultiplier) },
      Button: {
        backgroundColor: businessStyles.actionColor,
        ...Font(font, 24 * fontSizeMultiplier),
        borderRadius: businessStyles.buttonBorderRadius,
      },
      ButtonDisabled: {
        backgroundColor: "#aaa",
        ...Font(font, 24 * fontSizeMultiplier),
        textTransform: "none",
      },
      CategoryMenu: {
        color: businessStyles.backgroundColor,
        background: businessStyles.backgroundColor,
        ...Font(font, 22 * fontSizeMultiplier),
        textTransform: "none",
      },
      BranchListItem: {
        ...Font(font, 22 * fontSizeMultiplier),
        accentColor: businessStyles.accentColor,
        distanceFontSize: 16,
        actionColor: businessStyles.actionColor,
        backgroundColor: businessStyles.cardBackgroundColor,
        cardBorderRadius: businessStyles.cardBorderRadius,
        textTransform: "none",
      },
      Tabs: {
        ...Font(font, 22 * fontSizeMultiplier),
        ...(businessStyles.tabsHeaderFontSize && {
          fontSize: businessStyles.tabsHeaderFontSize,
        }),
        padding: "0 4px",
        textAlign: "center",
        backgroundColor: businessStyles.backgroundColor,
      },
      SelectedTab: { backgroundColor: businessStyles.backgroundColor },
      OrderTitle: { ...Font(font, 22 * fontSizeMultiplier) },

      OrderMenuTitle: {
        ...Font(font, 30 * fontSizeMultiplier),
        textTransform: "none",
      },
      OrderListCardTitle: {
        ...Font(font, 22 * fontSizeMultiplier),
        textTransform: "none",
      },

      CardTitle: {
        ...Font(font, businessStyles * fontSizeMultiplier.cardTitleSize),
        textTransform: "none",
      },
      Signup: {
        ...Font(font, 30 * fontSizeMultiplier),
        color: businessStyles.accentColor,
      },

      GiftActionTitle: {
        ...Font(font, 28 * fontSizeMultiplier),
        color: businessStyles.accentColor,
        textAlign: "center",
      },
      GiftActionHeader: {
        ...Font(font, 20 * fontSizeMultiplier),
        color: businessStyles.accentColor,
        textAlign: "center",
      },

      ServiceOptionBox: {
        borderRadius: businessStyles.cardBorderRadius,
        background: businessStyles.cardBackgroundColor,
      },
      ServingOptionTitle: {
        ...Font(font, 22 * fontSizeMultiplier),
        color: businessStyles.accentColor,
      },
      ChangeServingOptionLink: { color: businessStyles.actionColor },

      Card: {
        overflow: "hidden",
        background: businessStyles.cardBackgroundColor,
        borderRadius: businessStyles.cardBorderRadius,
      },
      Input: { background: businessStyles.inputOnCardBackgroundColor },
      Loader: { color: businessStyles.accentColor },
      EmptyBag: {
        ...Font(font, 32 * fontSizeMultiplier),
        color: businessStyles.accentColor,
      },
      GiftsHeaderTitle: { ...Font(font, 24 * fontSizeMultiplier) },
      GiftsHeaderSteps: { ...Font(font, 12 * fontSizeMultiplier) },

      PurchaseEvent: {
        fontSize: 14,
        ...Font(font, 20 * fontSizeMultiplier),
        color: businessStyles.accentColor,
        textAlign: "center",
        margin: 16,
      },
      ExternalLinkButton: {
        ...Font(font, 18 * fontSizeMultiplier),
      },
      TitleOnBackground: {
        color:
          businessStyles.titleOnBackgroundColor || businessStyles.textColor,
      },
      tastePrefrencesOptions: ["glutenfree", "vegeterian", "vegan"],
      App: { background: businessStyles.backgroundColor },
      StartNewOrderPopupTitle: Font(font, 38 * fontSizeMultiplier),
      MenuHeader: {
        fontSize: "1.5rem",
        ...Font(font, 36 * fontSizeMultiplier),
      },
      desktopLocateButtonColor: businessStyles.actionColor,
      iconPath: "/map_icons/default/",
      pricesFixedPoint: businessStyles.pricesFixedPoint || 2,
      iconPackage: "default",
      categoryMenuSelectedColor: businessStyles.accentColor,
      headerAvatarBackgroundColor: businessStyles.actionColor,
      headerAvatarColor: "white",
      inputValidColor: businessStyles.actionColor,
      footerTextColor: businessStyles.textColor,
      GiftCardTitle: { fontSize: "1.4rem", ...Font(font, 30) },
      splashScreenButtonColor: businessStyles.actionColor,
      lockIconColor: "#fff",
    }),
  );

module.exports.applyBusinessStyles = applyBusinessStyles;

module.exports.breakpoints = {
  values: {
    xs: 0,
    sm: 768, // * MUI default is 600
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};
