const returnTextOrError = require('./returnTextOrError');

module.exports = (url, payload, headers) =>
  fetch(url, {
    headers: {
      // Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .then(returnTextOrError)
    .catch(error => {
      console.log({ error });
      throw 'Error - failed to fetch';
    });
