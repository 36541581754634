import React from "react";
import toPairs from "lodash/toPairs";
import { PAYMENT_ID } from "../api-services/constants";

const jsonCssToStringCss = (styleJson) => {
  const styleString = toPairs(styleJson).reduce(
    (styleString, [propName, propValue]) => {
      return `${styleString}${propName.replace(
        /([A-Z])/g,
        (matches) => `-${matches[0].toLowerCase()}`,
      )}:${propValue};`;
    },
    "",
  );
  return styleString;
};

export const getHiddenFields = ({ paymentType } = {}, options = {}) => {
  switch (paymentType) {
    case PAYMENT_ID.SPREEDLY_PURCHASE:
    case PAYMENT_ID.SPREEDLY_TOAST:
      const tokenHiddenInput = (
        <input
          type="hidden"
          name="payment_method_token"
          id="payment_method_token"
        />
      );
      const displayNoneStyle = { display: "none" };
      return options.savedPaymentMethod
        ? [
            <div type="hidden" id="spreedly-number" style={displayNoneStyle} />,
            tokenHiddenInput,
          ]
        : tokenHiddenInput;
    default:
      return null;
  }
};

export const configureHostedField = ({
  spreedlyFieldKey,
  placeholder,
  style,
  type,
}) => {
  window.Spreedly.setFieldType(spreedlyFieldKey, type);
  if (spreedlyFieldKey === "number") {
    window.Spreedly.setNumberFormat("prettyFormat");
  }

  window.Spreedly.setStyle(spreedlyFieldKey, jsonCssToStringCss(style));
  window.Spreedly.setPlaceholder(spreedlyFieldKey, placeholder);
};

export const setConfiguredFieldStyle = ({ spreedlyFieldKey, style }) => {
  window.Spreedly.setStyle(spreedlyFieldKey, jsonCssToStringCss(style));
};
