const OPEN_LINK_IN_BROWSER = "OPEN_LINK_IN_BROWSER";

module.exports = (target) => (e) => {
  e.preventDefault();
  if (typeof window.postMessage === "function") {
    window.postMessage(
      JSON.stringify({ type: OPEN_LINK_IN_BROWSER, link: target }),
    );
  }
};
