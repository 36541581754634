import classnames from "classnames";
import debounce from "lodash/debounce";
import React from "react";
import * as styles from "./index.module.scss";

export default class GiftNotificationBadge extends React.Component {
  state = { shouldBounce: false };

  clearBounce = debounce(() => this.setState({ shouldBounce: false }), 200);

  componentWillUnmount() {
    clearTimeout(this.clearBounceTimeoutRef);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.amountOfGiftsUnseen !== this.props.amountOfGiftsUnseen) {
      this.bounce();
    }
  }

  bounce = () => {
    this.setState({ shouldBounce: true }, () => {
      this.clearBounceTimeoutRef = setTimeout(this.clearBounce, 1000);
    });
  };

  render() {
    const {
      appStyles,
      amountOfGiftsUnseen,
      classNames,
      headerStyle,
      homePageNoIconsStyle,
      withBounce,
    } = this.props;
    const { Button = {} } = appStyles;
    return (
      amountOfGiftsUnseen > 0 && (
        <span
          className={classnames(
            classNames,
            styles.GiftsNotification,
            withBounce && this.state.shouldBounce && styles.Bounce,
          )}
          style={{
            border: appStyles.Button.border,
            backgroundColor: headerStyle ? "red" : appStyles.Button.color,
            color: headerStyle ? "#fff" : appStyles.Button.backgroundColor,
            ...(homePageNoIconsStyle && { top: -10, left: 7 }),
            ...appStyles.GiftNotificationBadge,
            textShadow: "none",
            letterSpacing: "unset",
          }}
        >
          {amountOfGiftsUnseen}
        </span>
      )
    );
  }
}
