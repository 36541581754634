import classnames from 'classnames';
import React from 'react';
import * as styles from './index.module.scss';

export default ({ small, appStyles = {}, classNames, style }) => {
  const { Loader } = appStyles;
  return (
    <span
      className={classnames(styles.CircleLoader, small && styles.Small, classNames)}
      style={{ ...Loader, ...style }}
    />
  );
};
