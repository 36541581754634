import React from "react";
import { Dialog, IconButton } from "@mui/material";
import { makeStyles } from "../AppContainer/mui-theme";
import { Close } from "@mui/icons-material";

function Modal({ children, appStyles = {}, style, ...props }) {
  const { classes } = makeStyles()((theme, { bodyFont, textColor, backgroundColor, bodyFontWeight, rtl }) => ({
    dialog: {
      "& .MuiDialog-paper": {
        boxSizing: "border-box",
        fontFamily: bodyFont,
        fontWeight: bodyFontWeight,
        color: textColor,
        background: backgroundColor,
        borderRadius: 5,
        padding: 20,
        direction: rtl && "rtl",
        ...style,
      },
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: textColor,
      zIndex: 10,
    },
  }))(appStyles);

  return (
    <Dialog scroll="body" {...props} className={classes.dialog}>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={props.onClose}
        size="large"
        id="close-btn"
      >
        <Close />
      </IconButton>
      {children}
    </Dialog>
  );
}

export default Modal;
