import map from 'lodash/map';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

export default ({ batches, couponInstances, couponsUncheckedByUser, couponsCheckedByUser }) => {
  // the batches are sorted from most valuable to lowest value coupon batches, therefore using _.find to match,
  // will make the job.
  // first we will filter couponsUncheckedByUser and couponsCheckedByUser with couponInstances
  const availableCouponInstanceIds = _.map(couponInstances, 'id');
  const uncheckedByUserAndStillRelevant = _.intersection(couponsUncheckedByUser, availableCouponInstanceIds);
  const checkedByUserAndStillRelevant = _.intersection(couponsCheckedByUser, availableCouponInstanceIds);

  const bestMatchingBatchIndex = _.findIndex(batches, batch => {
    const { couponInstanceIds: batchCouponInstanceIds } = batch;
    const checkedAndMissing = _.difference(checkedByUserAndStillRelevant, batchCouponInstanceIds);

    if (checkedAndMissing.length > 0) {
      return false;
    }

    const hasUncheckedAndInThisBatch = !_.isEmpty(
      _.intersection(uncheckedByUserAndStillRelevant, batchCouponInstanceIds)
    );

    return !hasUncheckedAndInThisBatch;
  });
  return _.isNumber(bestMatchingBatchIndex) ? bestMatchingBatchIndex : -1;
};
