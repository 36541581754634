import classnames from "classnames";
import { withPrefix } from "gatsby";
import React from "react";
import Footer from "./Footer";
import * as styles from "./index.module.scss";
import MuiTheme from "./mui-theme";

class AppContainer extends React.Component {
  render() {
    const {
      children,
      id,
      style,
      classNames,
      relativePosition,
      appStyles = {},
      mobileAuthViewOpen,
      withExtraHeader,
      checkoutOpen,
    } = this.props;

    return (
      <MuiTheme appStyles={appStyles}>
        <div
          className={classnames(
            styles.AppContainer,
            mobileAuthViewOpen && styles.MobileAuthViewOpen,
            relativePosition && styles.RelativePosition,
            appStyles.rtl && "rtl",
            classNames,
            checkoutOpen && styles.CheckoutSideBarOpen,
            withExtraHeader && styles.WithExtraHeader,
            appStyles.bigDesktopHeader && styles.BigDesktopHeader,
          )}
          id={id}
          style={{
            ...style,
            background: appStyles.backgroundColor,
            ...(appStyles.bodyFont && { fontFamily: appStyles.bodyFont }),
            ...(appStyles.bodyFontWeight && {
              fontWeight: appStyles.bodyFontWeight,
            }),
            ...(appStyles.bodyTextTransform && {
              textTransform: appStyles.bodyTextTransform,
            }),
            ...(appStyles.bodyFontStyle && {
              fontStyle: appStyles.bodyFontStyle,
            }),
            color: appStyles.textColor,
            ...(appStyles.fontVariantNumeric && { fontVariantNumeric: appStyles.fontVariantNumeric }),
          }}
        >
          {children}
        </div>
      </MuiTheme>
    );
  }
}

AppContainer.WebsiteSection = ({ children, fullwidth, contentDirection = 'row', sectionStyle, contentStyle, sectionClassNames, contentClassNames }) => {
  return (
    <div className={sectionClassNames} style={{
      height: '100%',
      overflowY:'auto',
      ...sectionStyle,
    }}>
      <div className={contentClassNames} style={{
        width: '100%',
        display: 'flex',
        margin: '0 auto',
        maxWidth: fullwidth ? null : '1400px',
        flexDirection: contentDirection,
        ...contentStyle,
      }}>
        {children}
      </div>
    </div>
  )
}

AppContainer.AttachedContent = ({ children, style, classNames }) => {
  return (
    <div
      style={style}
      className={classnames(styles.AttachedContent, classNames)}
    >
      {children}
    </div>
  );
};

AppContainer.RelativeWrapper = ({ children, style, classNames }) => {
  return (
    <div
      style={style}
      className={classnames(styles.RelativeWrapper, classNames)}
    >
      {children}
    </div>
  );
};

AppContainer.ResponsiveWrapper = ({
  children,
  style,
  fullscreen,
  classNames,
}) => {
  return (
    <div
      style={style}
      className={classnames(
        styles.ResponsiveWrapper,
        fullscreen && styles.Fullscreen,
        classNames,
      )}
    >
      {children}
    </div>
  );
};

AppContainer.Content = ({
  children,
  style,
  appStyles = {},
  tightBottom,
  classNames,
  sidebarMode,
  modalMode,
  animate,
}) => {
  const background = appStyles.backgroundImage
    ? `url(${withPrefix(appStyles.backgroundImage)})`
    : appStyles.background || appStyles.backgroundColor;
  return (
    <div
      className={classnames(
        styles.Content,
        tightBottom && styles.TightBottom,
        sidebarMode && styles.SidebarMode,
        modalMode && styles.ModalMode,
        animate && styles.AnimateOnMount,
        appStyles.rtl && "rtl", // TODO: try to remove this
        classNames,
      )}
      style={{
        background,
        ...(appStyles.backgroundSize && {
          backgroundSize: appStyles.backgroundSize,
        }),
        ...style,
      }}
    >
      {children}
    </div>
  );
};

AppContainer.SecondaryContent = ({
  children,
  style,
  appStyles = {},
  tightBottom,
  classNames,
}) => {
  const { App = {} } = appStyles;

  return (
    <div
      className={classnames(
        styles.Content,
        styles.SecondaryContent,
        tightBottom && styles.TightBottom,
        classNames,
      )}
      style={{ ...style, ...App }}
    >
      {children}
    </div>
  );
};

AppContainer.CenteredColumn = ({ children, style, className }) => (
  <div className={classnames(styles.CenteredColumn, className)} style={style}>
    {children}
  </div>
);

AppContainer.ErrorMessage = ({ children, style }) => (
  <div className={styles.ErrorMessage} style={style}>
    {children}
  </div>
);

AppContainer.Footer = Footer;

export default AppContainer;
