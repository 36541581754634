import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import moment from "moment";

export const isNewUser = user => {
  const preferences = get(user, "userDetails.data.preferences");
  return !(preferences && !isEmpty(keys(preferences)));
};

export const getMemeberSinceDate = (user, T) => {
  const date = get(user, "userDetails.data.registrationDate");

  if (date && date.month && date.day && date.year) {
    return `${T("Member since")} ${moment()
      .year(date.year)
      .month(date.month - 1)
      .date(date.day)
      .format("MMM Do YYYY")}`;
  } else {
    return "-";
  }
};
