import times from 'lodash/times';

const COUNTRY_DATES = {
  DE: {
    WEEKDAYS_SHORT: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    WEEKDAYS_LONG: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    MONTHS: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    MONTHS_SHORT: ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Juni', 'Juli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dez'],
  },
  EN: {
    WEEKDAYS_LONG: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    WEEKDAYS_SHORT: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    MONTHS: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    MONTHS_SHORT: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
  },
  HE: {
    WEEKDAYS_LONG: ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'],
    WEEKDAYS_SHORT: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
    MONTHS: [
      'ינואר',
      'פברואר',
      'מרץ',
      'אפריל',
      'מאי',
      'יוני',
      'יולי',
      'אוגוסט',
      'ספטמבר',
      'אוקטובר',
      'נובמבר',
      'דצמבר',
    ],
    MONTHS_SHORT: times(12, month => (month + 1).toString()),
  },
};

export default {
  'en-US': {
    ...COUNTRY_DATES.EN,
    firstDayOfWeek: 1,
    hourFormat: 'hh:mm a',
  },
  'en-CA': {
    ...COUNTRY_DATES.EN,
    firstDayOfWeek: 1,
    hourFormat: 'hh:mm a',
  },
  'he-IL': {
    ...COUNTRY_DATES.HE,
    firstDayOfWeek: 0,
    hourFormat: 'HH:mm',
  },
  'de-CH': {
    ...COUNTRY_DATES.DE,
    firstDayOfWeek: 1,
    hourFormat: 'HH:mm',
  },
  'de-DE': {
    ...COUNTRY_DATES.DE,
    firstDayOfWeek: 1,
    hourFormat: 'HH:mm',
  },
};
