import isEmpty from 'lodash/isEmpty';
export const PAYMENTS_OS_SCRIPT = 'https://js.paymentsos.com/v2/latest/token.min.js';

export default class PaymentsOSAPI {
  constructor(publicKey) {
    if (isEmpty(publicKey)) {
      console.error('Missing Public Key for Payment OS');
    }
    this.api = window.POS;

    this.api.setEnvironment(process.env.GATSBY_APP_ENV != 'production' ? 'test' : 'live');

    this.api.setPublicKey(publicKey);
  }

  tokenize = request =>
    new Promise((resolve, reject) => {
      this.api.tokenize(request, response => {
        const result = JSON.parse(response);

        if (result.more_info) {
          console.warn(result);
          reject(result.more_info);
        } else {
          resolve(result);
        }
      });
    });
}
