import classnames from "classnames";
import _ from "lodash";
import React from "react";
import QuantityInput from "../quanitity-input";
import * as styles from "./CardNumericGroup.module.scss";

class CountInputGroup extends React.Component {
  // not handling limit
  constructor(props) {
    super(props);
    const { children, checked = [], limit } = props;

    const checkedIndices = _.filter(
      _.map(checked, (value) =>
        children.findIndex((c) => c.props.value === value),
      ),
      (index) => index !== -1,
    );

    this.state = { checkedIndices, disabled: checkedIndices.length >= limit };
  }

  get values() {
    const { children } = this.props;
    _.map(this.state.checkedIndices, (index) => children[index].props.value) ||
      [];
  }

  // This is the case to handle late arriving props,
  // and set the state according to the value
  // as long as it"s not disabled
  // not handling limit
  componentWillReceiveProps(nextProps) {
    const { checked } = nextProps;
    if (checked) {
      const children = nextProps.children;
      const checkedIndices = _.filter(
        _.map(checked, (value) =>
          children.findIndex((c) => c.props.value === value),
        ),
        (index) => index !== -1,
      );
      this.setState({ checkedIndices });
    }
  }

  onChange = (index, selectedAmount) => {
    const { onChange, children, limit } = this.props;
    const { checkedIndices } = this.state;
    const child = children[index];
    if (!child) return;

    if (
      this.state.checkedIndices.length >= limit &&
      !_.includes(this.state.checkedIndices, index)
    )
      return;

    const nextCheckedIndices = _.concat(
      _.filter(this.state.checkedIndices, (i) => i != index),
      _.times(selectedAmount, () => index),
    );

    this.setState({ checkedIndices: nextCheckedIndices }, () => {
      onChange &&
        onChange(
          _.map(
            this.state.checkedIndices,
            (index) => children[index].props.value,
          ) || [],
        );
    });
  };

  renderChild = (child, index, checked, disabled) => {
    const {
      children,
      horizontal,
      limit,
      appStyles: { rtl } = {},
      padding = 10,
      wrap,
    } = this.props;
    const { checkedIndices } = this.state;

    return React.cloneElement(child, {
      horizontal,
      index,
      padding,
      disabled:
        disabled || (limit > 1 && !checked && checkedIndices.length >= limit),
      checked,
      rtl,
      key: index,
      last: index === children.length - 1,
      onChange: this.onChange,
      ...child.props,
    });
  };

  render() {
    const { checkedIndices } = this.state;
    const {
      horizontal,
      limit,
      children,
      wrap,
      appStyles,
      disabled,
      ...props
    } = this.props;
    const style = horizontal
      ? {
          display: "inline-flex",
          justifyContent: "space-around",
          width: "100%",
        }
      : {};

    return (
      <div
        style={style}
        {...props}
      >
        {_.map(children, (child, index) =>
          this.renderChild(
            child,
            index,
            _.includes(checkedIndices, index),
            _.includes(disabled, index),
          ),
        )}
      </div>
    );
  }
}

class CountInput extends React.Component {
  constructor() {
    super();
    this.inputRef = React.createRef();
  }

  getStyles = () => {
    const {
      horizontal,
      last,
      padding = 0,
      rootColor = "currentColor",
      disabled,
      disabledColor = "#e1e1e1",
      label,
      outerBorderColor = "#E0E0E0",
      appStyles,
    } = this.props;

    return {
      root: {
        cursor: disabled ? "not-allowed" : "pointer",
        color: disabled ? disabledColor : rootColor,
        opacity: disabled ? 0.55 : 1,
        borderColor: disabled ? disabledColor : outerBorderColor,
        padding: padding,
        marginBottom: horizontal ? 0 : padding || 10,
      },
      label: {
        color: appStyles.actionColor,
        borderStyle: "none",
        padding: padding / 2,
        marginBottom: horizontal ? 0 : padding / 2,
        textAlign: "center",
      },
      checked: {
        borderColor: outerBorderColor,
        color: appStyles.actionColor,
        fontWeight: "bold",
      },
    };
  };

  onClick = (selectedAmount) => {
    const { onChange, checked, index, disabled, value } = this.props;
    !disabled && onChange && onChange(index, selectedAmount);
  };

  renderChild = (child, checked) => {
    const {
      selectedChildrenClassName,
      pointColor,
      rtl,
      outerBorderColor,
    } = this.props;
    const style = { color: pointColor };

    return React.cloneElement(child, {
      checked,
      rtl,
      outerBorderColor,
      className: classnames(
        checked && selectedChildrenClassName,
        child.props.className,
      ),
      style: style,
    });
  };

  render() {
    const {
      T = _.identity,
      value,
      values,
      checked,
      iconSize,
      iconInnerSize,
      rootColor,
      pointColor,
      children,
      disabled,
      disabledColor,
      outerBorderColor = "#777",
      padding,
      label,
      rtl,
      horizontal,
      selectedChildrenClassName,
      classNames,
      appStyles,
      max,
    } = this.props;

    const style = this.getStyles();
    const buttonStyle = _.assign({}, style.root, checked ? style.checked : {});
    const labelStyle = _.assign({}, style.root, style.label);

    return (
      <div
        style={buttonStyle}
        role="number"
        aria-checked={Boolean(checked)}
        className={classnames(classNames, styles.NumericInputWrapper)}
      >
        <div
          style={
            horizontal
              ? {
                  display: "flex",
                  flexDirection: "column-reverse",
                  height: "100%",
                  justifyContent: "space-between",
                }
              : { display: "inline-flex", width: "100%" }
          }
        >
          <span
            className={classnames(
              styles.SmallQuantityInputWrapper,
              appStyles.rtl && styles.RTL,
              !disabled &&
                _.filter(values, (val) => val === value).length === max &&
                styles.DisabledPlus,
              !disabled &&
                _.filter(values, (val) => val === value).length === 0 &&
                styles.DisabledMinus,
            )}
            style={{
              ...appStyles.Card,
              borderRadius: appStyles.buttonBorderRadius,
              height: "100%",
              alignItems: "inherit",
              padding: "0px",
              margin: "0px",
            }}
          >
            <QuantityInput
              T={T}
              size="small"
              appStyles={appStyles}
              readOnly
              value={_.filter(values, (val) => val === value).length}
              min={0}
              max={
                disabled ? _.filter(values, (val) => val === value).length : max
              }
              onChange={this.onClick}
              onClick={(e) => {
                this.inputRef.current.refsInput.readOnly = true;
              }}
              ref={this.inputRef}
            />
            {horizontal && label && (
              <div style={labelStyle}>
                <div>{label}</div>
              </div>
            )}
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                ...(!horizontal && { flexGrow: 1 }),
                padding: "0px 10px 0px 10px",
              }}
            >
              {horizontal ? this.renderChild(children, checked) : children}
            </div>
          </span>
        </div>

        {!horizontal && label && (
          <div style={labelStyle}>
            <div>{label}</div>
          </div>
        )}
      </div>
    );
  }
}
export const CardNumericGroup = (props) => <CountInputGroup {...props} />;

export const CardNumericInput = (props) => <CountInput {...props} />;
