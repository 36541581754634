import React from "react";
import { makeStyles } from "../AppContainer/mui-theme";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import DecreaseIcon from "@mui/icons-material/Remove";
import InreaseIcon from "@mui/icons-material/Add";
import keyCode from "keycode";
import { identity, isNumber } from "lodash";

const QuantityInput = React.forwardRef(({
  T = identity,
  appStyles = {},
  onChange,
  min,
  max,
  readOnly,
  precision,
  withPaper,
  size = "medium",
  value,
}, ref) => {
  const { classes } = useStyles();
  const [currentValue, setCurrentValue] = React.useState(value);

  const handleOnChange = onChange;

  React.useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  }, [value]);

  const increament = (e) => {
    const nextValue = Math.min(max, currentValue + 1);

    setCurrentValue(nextValue);
    handleOnChange(nextValue);
  };

  const decreament = (e) => {
    const nextValue = Math.max(min, currentValue - 1);
    setCurrentValue(nextValue);
    handleOnChange(nextValue);
  };

  const handleKeyDown = (e) => {
    if (keyCode.isEventKey(e, "up")) {
      return increament(e);
    }
    if (keyCode.isEventKey(e, "down")) {
      return decreament(e);
    }
  };

  const handleNumberInputChanged = (e) => {
    const nextValue = parseInt(e.target.value);
    if (nextValue && nextValue <= max && nextValue >= min) {
      setCurrentValue(nextValue);

      handleOnChange(nextValue);
    }
  };
  const isSmall = size === "small";
  const Wrapper = withPaper ? Paper : "form";
  const buttonStyles = isSmall
    ? {
        color: appStyles.actionColor,
        padding: 2,
        borderRadius: 6,
        border: `2px solid ${appStyles.actionColor}`,
        width: 32,
        height: 32,
      }
    : undefined;

  const disbaledButtonStyles = isSmall
    ? {
        ...buttonStyles,
        opacity: 0.3,
      }
    : undefined;
  const inputStyles = isSmall
    ? { color: appStyles.actionColor, width: 30, minHeight: 32 }
    : undefined;

  const decreamentDisabled =
    isNumber(min) && isNumber(currentValue) && min == currentValue;
  const increamenteDisabled =
    isNumber(max) && isNumber(currentValue) && max == currentValue;
  return (
    <Wrapper
      {...withPaper && { component: "form" }}
      className={classes.root}
      style={{ display: "flex" }}
    >
      <IconButton
        className={classes.iconButton}
        aria-label={T("Decrement Quantity")}
        disabled={decreamentDisabled}
        size={size}
        style={min < currentValue ? buttonStyles : disbaledButtonStyles}
        onClick={decreament}
      >
        <DecreaseIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        value={parseInt(currentValue)}
        onChange={handleNumberInputChanged}
        onKeyDown={handleKeyDown}
        inputProps={{
          "aria-label": T("Item Quantity"),
          type: "text",
          inputMode: "numeric",
          pattern: "[0-9]*",
          style: inputStyles,
          readOnly,
        }}
        ref={ref}
      />
      <IconButton
        className={classes.iconButton}
        aria-label={T("Increment Quantity")}
        disabled={increamenteDisabled}
        size={size}
        style={max > currentValue ? buttonStyles : disbaledButtonStyles}
        onClick={increament}
      >
        <InreaseIcon />
      </IconButton>
    </Wrapper>
  );
});

const useStyles = makeStyles()((theme) => ({
  root: {
    background: "inherit",
    color: "inherit",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    fontFamily: "inherit",
    flexDirection: "row",
  },
  input: {
    fontFamily: "inherit",
    color: "inherit",
    width: 40,
    minHeight: 40,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    "& > input": {
      textAlign: "center",
    },
  },
  iconButton: {
    color: "inherit",
    width: 44,
    height: 44,
    padding: 10,
  },
}));

export default QuantityInput;
