import map from "lodash/map";
import { NOW } from "./constants";

export default ({ pickupTimes, T }) =>
  map(pickupTimes.data, pickupTime => {
    if (pickupTime === NOW) {
      return {
        label: T
          ? T(
              `${T("Now (Up to")} ${pickupTimes.firstPickupTimeDelay} ${T(
                "min)"
              )}`
            )
          : `Now (Up to ${pickupTimes.firstPickupTimeDelay} min)`,
        value: `Now (Up to ${pickupTimes.firstPickupTimeDelay} min)`
      };
    }
    return {
      label: pickupTime,
      value: pickupTime
    };
  });
