// extracted by mini-css-extract-plugin
export var Button = "Footer-module--Button--66515";
export var Center = "Footer-module--Center--fe842";
export var Dark = "Footer-module--Dark--6bf8e";
export var Disabled = "Footer-module--Disabled--1a770";
export var Error = "Footer-module--Error--f5d3c";
export var Footer = "Footer-module--Footer--3b9c3";
export var HideOnDesktop = "Footer-module--HideOnDesktop--0ed3e";
export var Label = "Footer-module--Label--d74a6";
export var Left = "Footer-module--Left--ea02b";
export var Light = "Footer-module--Light--5d4fc";
export var RTL = "Footer-module--RTL--3a456";
export var Right = "Footer-module--Right--2043c";
export var SidebarMode = "Footer-module--SidebarMode--8c526";
export var Spread = "Footer-module--Spread--c05e0";
export var Tall = "Footer-module--Tall--f0e20";
export var fadeIn = "Footer-module--fadeIn--982b7";