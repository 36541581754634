import { getConstantsFor } from "../asyncAction";

export const OPEN_AUTH_LOGIN = "OPEN_AUTH_LOGIN";
export const OPEN_AUTH_SIGNUP = "OPEN_AUTH_SIGNUP";
export const RESET_AUTH = "RESET_AUTH";
export const SHOW_VERIFY_CODE = "SHOW_VERIFY_CODE";
export const CLOSE_VERIFY_CODE = "CLOSE_VERIFY_CODE";
export const CLOSE_PRIVACY_CONSENT = "CLOSE_PRIVACY_CONSENT";
export const LOGIN = getConstantsFor("USER_LOGIN");
export const VERIFY_CODE = getConstantsFor("VERIFY_CODE");
export const SIGNUP = getConstantsFor("USER_SIGNUP");
export const CHARGE_CARD = getConstantsFor("CHARGE_CARD");
export const LOAD_CARD = getConstantsFor("LOAD_CARD");
export const LOAD_GIFTS = getConstantsFor("LOAD_GIFTS");
export const LOAD_PAYMENT_METHODS = getConstantsFor("LOAD_PAYMENT_METHODS");
export const REMOVE_PAYMENT_METHOD = getConstantsFor("REMOVE_PAYMENT_METHOD");
export const SET_DEFAULT_PAYMENT_METHOD = "SET_DEFAULT_PAYMENT_METHOD";
export const ADD_PAYMENT_METHOD = getConstantsFor("ADD_PAYMENT_METHOD");
export const SAVE_PROFILE = getConstantsFor("SAVE_PROFILE");
export const DONATE_GIFT = getConstantsFor("DONATE_GIFT");
export const SHARE_GIFT = getConstantsFor("SHARE_GIFT");
export const LOAD_CONTACTS = getConstantsFor("LOAD_CONTACTS");
export const LOAD_LATEST_ORDERS = getConstantsFor("LOAD_LATEST_ORDERS");
export const LOAD_HISTORY = getConstantsFor("LOAD_HISTORY");
export const LOAD_PURCHASE = getConstantsFor("LOAD_PURCHASE");
export const LOAD_USER_DETAILS = getConstantsFor("LOAD_USER_DETAILS");
export const SELECT_GIFT_FOR_SHARE = "SELECT_GIFT_FOR_SHARE";
export const SELECT_CONTACT_FOR_GIFT_SHARE = "SELECT_CONTACT_FOR_GIFT_SHARE";
export const SELECT_GIFT_FOR_DONATION = "SELECT_GIFT_FOR_DONATION";
export const DISABLE_AUTO_GEOLOCATION = "DISABLE_AUTO_GEOLOCATION";
export const ENABLE_AUTO_GEOLOCATION = "ENABLE_AUTO_GEOLOCATION";
export const PRELOAD_USER = "PRELOAD_USER";
export const SKIP_PRELOAD_USER = "SKIP_PRELOAD_USER";
export const LOGOUT = "USER_LOGOUT";
export const ADD_DEEP_LINK_PARAMS_TO_USER = getConstantsFor("ADD_DEEP_LINK_PARAMS_TO_USER");
export const SAVE_PROFILE_PICTURE = getConstantsFor("SAVE_PROFILE_PICTURE");
export const SAVE_USER_LOCATION = "SAVE_USER_LOCATION";
export const SET_PROFILE_SIGNUP_FLOW_MODE = "SET_PROFILE_SIGNUP_FLOW_MODE";
export const SET_PROFILE_REGULAR_MODE = "SET_PROFILE_REGULAR_MODE";
export const SIGNUP_RESET_KEEP_DATA = "SIGNUP_RESET_KEEP_DATA";
export const MARK_PUSH_POPUP_SHOWN = "MARK_PUSH_POPUP_SHOWN";
export const REGISTER_PUSH_NOTIFICATIONS = getConstantsFor(
  "REGISTER_PUSH_NOTIFICATIONS",
);
export const REGISTER_PUSH_NOTIFICATION_FROM_NATIVE =
  "REGISTER_PUSH_NOTIFICATION_FROM_NATIVE";
export const INIT_UNIQUE_IDENTIFIER = "INIT_UNIQUE_IDENTIFIER";
export const SEND_FEEDBACK = getConstantsFor("SEND_FEEDBACK");
export const LOCATE_ME = getConstantsFor("LOCATE_ME");
export const PUSH_NOTIFICATION_ALERT_DENIED = "PUSH_NOTIFICATION_ALERT_DENIED";
export const RESET_HISTORY_CACHE = "RESET_HISTORY_CACHE";
export const GET_CASH_CARD_DETAILS = getConstantsFor("GET_CASH_CARD_DETAILS");
export const RECHARGE_CARD = getConstantsFor("RECHARGE_CARD");
export const ADD_PAYMENT_METHOD_AND_CHECKOUT = getConstantsFor(
  "ADD_PAYMENT_METHOD_AND_CHECKOUT",
);
export const SHARE_GIFT_DESKTOP = "SHARE_GIFT_DESKTOP";
export const GET_PENDING_PURCHASE_EVENTS = getConstantsFor(
  "GET_PENDING_PURCHASE_EVENTS",
);
export const RESPOND_TO_PURCHASE_EVENT_FROM_POS = getConstantsFor(
  "RESPOND_TO_PURCHASE_EVENT_FROM_POS",
);
export const GET_PURCHASE_EVENT_STATUS = getConstantsFor(
  "GET_PURCHASE_EVENT_STATUS",
);
export const GET_COUPON_BATCHES_FOR_PURCHASE_EVENT = getConstantsFor(
  "GET_COUPON_BATCHES_FOR_PURCHASE_EVENT",
);
export const GET_PRIVACY_CONSENT_DETAILS = getConstantsFor(
  "GET_PRIVACY_CONSENT_DETAILS",
);
export const SEND_PRIVACY_CONSENT = getConstantsFor("SEND_PRIVACY_CONSENT");
export const SHOW_PRIVACY_CONSENT = "SHOW_PRIVACY_CONSENT";
export const SHOW_PRIVACY_CONSENT_INFO = "SHOW_PRIVACY_CONSENT_INFO";
export const NOTIFICATION_RECEIVED = "NOTIFICATION_RECEIVED";
export const GET_UNSEEN_GIFTS_AMOUNT = getConstantsFor(
  "GET_UNSEEN_GIFTS_AMOUNT",
);
export const RESET_UNSEEN_GIFTS_AMOUNT = "RESET_UNSEEN_GIFTS_AMOUNT";
export const SELECT_CONTACT_FOR_GIFT_CARD = "SELECT_CONTACT_FOR_GIFT_CARD";
export const RESET_GIFT_CARD = "RESET_GIFT_CARD";
export const SET_COUPONS_CHECKED_FOR_PURCHASE_EVENT =
  "SET_COUPONS_CHECKED_FOR_PURCHASE_EVENT";
export const CREATE_GIFT_CARD = getConstantsFor("CREATE_GIFT_CARD");
export const GET_EXTERNAL_GIFT_CARDS = getConstantsFor(
  "GET_EXTERNAL_GIFT_CARDS",
);
export const RESET_ADD_EXTERNAL_GIFT_CARD = "RESET_ADD_EXTERNAL_GIFT_CARD";
export const ADD_EXTERNAL_GIFT_CARD = getConstantsFor("ADD_EXTERNAL_GIFT_CARD");
export const REMOVE_EXTERNAL_GIFT_CARD = getConstantsFor(
  "REMOVE_EXTERNAL_GIFT_CARD",
);
export const LOAD_LOYALTY_PROFILE = getConstantsFor("LOAD_LOYALTY_PROFILE");
export const TRADE_POINTS_FOR_COUPON = getConstantsFor(
  "TRADE_POINTS_FOR_COUPON",
);
export const LOAD_WAITING_ORDERS = getConstantsFor("LOAD_WAITING_ORDERS");
export const DELETE_ACCOUNT = getConstantsFor("DELETE_ACCOUNT");
export const SAVE_SUBSCRIPTION = getConstantsFor("SAVE_SUBSCRIPTION");
export const SET_SHOULD_SIGN_UP_ON_PAYMENT = "SET_SHOULD_SIGN_UP_ON_PAYMENT";
export const SIGN_UP_ON_PAYMENT = "SIGN_UP_ON_PAYMENT";
export const TOGGLE_FAVORITE = getConstantsFor("FAVORITE");
export const SET_GIFT_CARD_AMOUNT = "SET_GIFT_CARD_AMOUNT";
export const SET_GIFT_CARD_RECIPIENT = "SET_GIFT_CARD_RECIPIENT";
export const SET_GIFT_CARD_SENDER = "SET_GIFT_CARD_SENDER";
export const SET_GIFT_CARD_TEXT_MESSAGE = "SET_GIFT_CARD_TEXT_MESSAGE";
