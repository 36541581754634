import React from "react";
import { IconButton, Modal, Slide } from "@mui/material";
import { makeStyles } from "../AppContainer/mui-theme";
import { Close } from "@mui/icons-material";
import { getAppMedia, mediaTypes } from "../../utils/media";
import Button from "../Button";
import Image from "../image";
import * as styles from "./index.module.scss";

export default ({ T, appStyles, onClose, open, backgroundColor }) => {
  const { classes } = makeStyles()((theme, { splashScreenButtonColor }) => ({
    closeButton: {
      position: "absolute",
      color: splashScreenButtonColor,
      right: theme.spacing(1),
      top: theme.spacing(1),
      zIndex: 10,
    },
    modal: {
      backgroundColor
    }
  }))(appStyles);

  const temporarySplashScreen =
    getAppMedia(mediaTypes.temporarySplashScreen, appStyles) || {};

  return temporarySplashScreen.imageKey ? (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.modal}
    >
      <Slide direction="up" in={open} timeout={1000} mountOnEnter unmountOnExit>
        <div className={styles.ImageWrapper}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="large"
          >
            <Close />
          </IconButton>
          <Image
            mediaType={mediaTypes.temporarySplashScreen}
            imagePreview={temporarySplashScreen.imagePreview}
            mediaKey={temporarySplashScreen.imageKey}
            sizes="768px"
            imgStyle={{ objectFit: "cover" }}
            wrapperStyle={{ width: "100%", height: "100%" }}
          />
          <Button
            centered
            linkStyle
            appStyles={appStyles}
            onClick={onClose}
            classNames={styles.SkipButton}
            style={{ color: appStyles.splashScreenButtonColor }}
            label={T("Skip")}
          >
            {T("Skip")}
          </Button>
        </div>
      </Slide>
    </Modal>
  ) : null;
};
