import React from "react";
import qs from "query-string";
import _, { reduce } from "lodash";
import { Link as GLink, navigate as gNavigateTo } from "gatsby";

export const getSearch = (href) => _.split(location.href, "?", 2)[1] || "";

const endSlashRegex = /([^\/]+)(\/+)/;

export const removeBackslashFromParamIfNeeded = (param) => {
  if (endSlashRegex.test(param)) {
    return param.match(endSlashRegex)[1];
  }
  return param;
};

export const removeBackslashFromPathfNeeded = (to) => {
  if (_.endsWith(to, "/")) {
    return to.slice(0, -1);
  }
  return to;
};

export const getParams = (location) => {
  if (_.get(location, "search")) {
    const params = qs.parse(removeBackslashFromPathfNeeded(location.search));
    const sanitizedParams = reduce(
      params,
      (acc, v, k) => {
        return { ...acc, [k]: removeBackslashFromParamIfNeeded(v) };
      },
      {},
    );

    return sanitizedParams;
  } else {
    return {};
  }
};

const addBackSlashIfNeeded = (to) => {
  if (_.includes(to, "?") && !_.includes(to, "/?")) {
    return _.replace(to, "?", "/?");
  }
  if (_.endsWith(to, "/") || _.includes("/?")) {
    return to;
  }

  return `${to}/`;
};

export const setParams = (location, params) => {
  if (typeof location === "object") {
    const targetParams = { ...getParams(location), ...params };
    const queryString = qs.stringify(targetParams);
    return `${location.pathname}?${queryString}`;
  } else if (typeof location === "string") {
    const { url, queryParams } = qs.parseUrl(
      removeBackslashFromPathfNeeded(location),
    );

    const targetParams = { ...queryParams, ...params };
    const queryString = qs.stringify(targetParams);
    return `${url}?${queryString}`;
  }
};

export const removeParams = (location, ...paramsToRemove) => {
  if (typeof location === "object") {
    const search = location.search.replace("?", "");
    const targetParams = _.omit(qs.parse(search), paramsToRemove);
    const queryString = qs.stringify(targetParams);
    return _.isEmpty(queryString)
      ? `${location.pathname}`
      : `${location.pathname}?${queryString}`;
  } else if (typeof location === "string") {
    const [pathname, search] = _.split(location, "?", 2);
    const targetParams = _.omit(qs.parse(search), paramsToRemove);
    const queryString = qs.stringify(targetParams);
    const pathnameWithSlash = addBackSlashIfNeeded(pathname);
    return _.isEmpty(queryString)
      ? `${pathnameWithSlash}`
      : `${pathnameWithSlash}?${queryString}`;
  }
};

export const LOGIN_REQUIRED_LINK = {
  GIFTS: "/gifts",
  SCAN: "/scan",
  REORDER: "/reorder",
  PREPAID: "/prepaid",
};

export const isLoggedInRequiredLink = (path) =>
  _.some(LOGIN_REQUIRED_LINK, (link) => _.includes(path, link));

export const navigateTo = (path, options) =>
  gNavigateTo(addBackSlashIfNeeded(path), options);

export const Link = ({ to, ...props }) => (
  <GLink {...{ to: addBackSlashIfNeeded(to), ...props }} />
);

export const hasUserCreds = (params = {}) =>
  Boolean(params.userId && params.secretGUID);
export const getUserCreds = (params = {}) =>
  _.pick(params, ["secretGUID", "userId", "eligableForDiscount"]);
export const isGroupReferral = (params = {}) =>
  Boolean(params.groupReferralUrl);

export const hasOrderRequiredParams = (params = {}) =>
  Boolean(params.branchId, params.servingOptionType);
export const getOrderParams = (params = {}) => {
  const {
    branchId,
    servingOptionType,
    deliveryAddress,
    longitude,
    latitude,
    city,
    street,
    number,
    eligableForDiscount,
    aggregatorId,
  } = _.pick(params, [
    "branchId",
    "servingOptionType",
    "deliveryAddress",
    "longitude",
    "latitude",
    "city",
    "street",
    "number",
    "eligableForDiscount",
    "aggregatorId",
  ]);

  const isDeliveryAddressValid =
    deliveryAddress &&
    longitude &&
    latitude &&
    street &&
    !_.isEmpty(number) &&
    city &&
    servingOptionType === "delivery";

  const orderState = {
    branchId,
    servingOptionType,
    eligableForDiscount,
    aggregatorId,
    deliveryAddress: isDeliveryAddressValid
      ? {
          geoPoint: { longitude, latitude },
          street,
          description: deliveryAddress,
          city,
          number,
        }
      : null,
  };
  return orderState;
};
