import { post } from "../utils/rest";

const ZEAMSTER_API =
  process.env.GATSBY_APP_ENV === "production"
    ? "https://api.olu.rest/v2/cardtickets"
    : "https://apiv2.sandbox.zeamster.com/v2/cardtickets";

const toTwoDigits = (number) => ("0" + parseInt(number)).slice(-2);

const dateFormatter = (month, year) =>
  [month, year.slice(2)].map(toTwoDigits).join("");

export const requestTicket = ({
  orderId: order_id,
  timestamp,
  hash: authorization,
  paymentAppId: location_id,
  cardHolderName,
  cardNumber: card_number,
  cvv,
  month,
  year,
}) =>
  post(ZEAMSTER_API, {
    cardHolderName,
    card_number,
    cvv,
    month,
    year,
    exp_date: dateFormatter(month, year),
    location_id,
    authorization,
    order_id,
    timestamp,
  });
