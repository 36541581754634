export const TIMEOUT = 'TIMEOUT';
export const USER_BLOCK = 'USER_BLOCK';

const options = {
  enableHighAccuracy: true,
  timeout: 15000,
};

export default () =>
  new Promise((resolve, reject) => {
    let completed = false;
    const timeoutTimer = setTimeout(() => {
      if (!completed) {
        reject(TIMEOUT);
      }
    }, options.timeout);

    const onSuccess = ({ coords }) => {
      clearTimeout(timeoutTimer);
      completed = true;
      console.log('got user coords:', coords);
      return resolve(coords);
    };

    const onError = err => {
      completed = true;
      clearTimeout(timeoutTimer);
      switch (err.code) {
        case 1:
        case 2:
          return reject(USER_BLOCK);
        case 3:
          return reject(TIMEOUT);
        default:
          return reject(err);
      }
    };

    console.log('getting current position...');
    navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
  });

export const STATUS_CODES = {
  TIMEOUT,
  USER_BLOCK,
};
