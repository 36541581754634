const BUSINESS_SERVICE = "BusinessService";
const USER_SERVICE = "UsersService";
const CARD_SERVICE = "CardsService";
const MENU_SERVICE = "MenuService";
const ORDER_SERVICE = "OrderService";
const COUPON_SERVICE = "CouponsService";
const PAYMENT_SERVICE = "PaymentsService";
const MEDIA_SERVICE = "MediaService";
const POS_SERVICE = "PointOfSaleService";
const GROUPS_SERVICE = "UsersGroupsService";
const SCAN_AND_PAY_SERVICE = "ScanAndPayService";
const API_ROOT =
  process.env.GATSBY_APP_ENV === "production"
    ? "https://bot.paidappfront.com/BeengoWebService/rest"
    : "https://test.beengo.me:8443/BeengoWebService/rest";

module.exports = {
  API_ROOT,
  BUSINESS: `${API_ROOT}/${BUSINESS_SERVICE}`,
  USER: `${API_ROOT}/${USER_SERVICE}`,
  CARD: `${API_ROOT}/${CARD_SERVICE}`,
  MENU: `${API_ROOT}/${MENU_SERVICE}`,
  ORDER: `${API_ROOT}/${ORDER_SERVICE}`,
  COUPON: `${API_ROOT}/${COUPON_SERVICE}`,
  PAYMENT: `${API_ROOT}/${PAYMENT_SERVICE}`,
  MEDIA: `${API_ROOT}/${MEDIA_SERVICE}`,
  POS: `${API_ROOT}/${POS_SERVICE}`,
  SCAN_AND_PAY: `${API_ROOT}/${SCAN_AND_PAY_SERVICE}`,
  GROUPS: `${API_ROOT}/${GROUPS_SERVICE}`,
  PLATFORM_WEB: "Web",
  PLATFORM_APP: "App",
  PLATFORM_IN_STORE_SIGNUP: "InStoreSignup",
  PAYMENT_ID: {
    ZOOZ: "zooz",
    ZEAMSTER: "zeamster",
    PAYMENTS_OS: "paymentsos",
    SPREEDLY_TOAST: "spreedlytoast",
    SPREEDLY_PURCHASE: "spreedlypurchase",
    CARD_CONNECT: "cardconnect",
    STRIPE: "stripe",
  },
};
