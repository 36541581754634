import React from "react";
import { LOGIN_TYPES } from "./index";

import CreditCardEmptyIcon from "../icons/CreditCardEmpty.svg";
import { default as GiftIcon, default as GiftWithHearts } from "../icons/Gift.svg";
import PizzaReorderIcon from "../icons/PizzaReorder.svg";
import ReorderIcon from "../icons/Reorder.svg";
import ScanIcon from "../icons/Scan.svg";

import * as styles from "./index.module.scss";

export default ({ type, appStyles, T }) => {
  return (
    <div>
      {getLoginIcon(type, appStyles, T)}
      <p
        className={styles.Subtitle}
        style={{
          ...(!appStyles.hideGiftIconOnLogin && {
            color: appStyles.accentColor,
          }),
        }}
      >
        {T(getLoginText(type, appStyles.hideGiftIconOnLogin))}
      </p>
    </div>
  );
};

const getLoginText = (type, hideGiftIconOnLogin) => {
  switch (type) {
    case LOGIN_TYPES.GIFTS:
      return "Login to see your rewards and feel the love";
      break;
    case LOGIN_TYPES.REORDER:
      return "Login to see your previous orders";
      break;
    case LOGIN_TYPES.SCAN:
      return "Login to scan when you visit the store";
      break;
    case LOGIN_TYPES.PREPAID:
      return "Login to use your prepaid card and get special deals";
      break;
    case LOGIN_TYPES.SUBSCRIPTION:
      return "Login to sign up to our subscription";
      break;
    default:
      return hideGiftIconOnLogin
        ? "Login to skip the line, feel the love and share the good."
        : "Login for faster ordering and special rewards.";
      break;
  }
};

const getLoginIcon = (type, appStyles, T) => {
  const { PageHeader = {}, TitleOnBackground = {} } = appStyles;
  const style = {
    ...PageHeader,
    ...TitleOnBackground,
    color: appStyles.accentColor,
  };
  switch (type) {
    case LOGIN_TYPES.GIFTS:
      return (
        <div className={styles.LoginIconWrapper} style={style}>
          <GiftIcon className={styles.LoginIcon} />
        </div>
      );
      break;
    case LOGIN_TYPES.REORDER:
      return (
        <div className={styles.LoginIconWrapper} style={style}>
          {appStyles.isPizza ? (
            <PizzaReorderIcon className={styles.LoginIcon} />
          ) : (
            <ReorderIcon className={styles.LoginIcon} />
          )}
        </div>
      );
      break;
    case LOGIN_TYPES.SCAN:
      return (
        <div className={styles.LoginIconWrapper} style={style}>
          <ScanIcon className={styles.LoginIcon} />
        </div>
      );
      break;
    case LOGIN_TYPES.PREPAID:
      return (
        <div className={styles.LoginIconWrapper} style={style}>
          <CreditCardEmptyIcon className={styles.LoginIcon} />
        </div>
      );
      break;
    default:
      return appStyles.hideGiftIconOnLogin ? (
        <h2 className={styles.Title} style={style}>
          {T("Welcome Back")}
        </h2>
      ) : (
        <div className={styles.LoginIconWrapper} style={style}>
          <GiftWithHearts className={styles.GiftIcon} />
        </div>
      );
      break;
  }
};
