import {get} from "lodash";
import {HOMEPAGE_TEMPLATE_KEY} from "../../utils/constants";

export function getTemplateFromAppStyles(appStyles) {
  const template = get(appStyles, 'homepageTemplateConfig.selectedTemplate');
  if (!template) {
    return appStyles.hasScrollableHomepageLayout ? HOMEPAGE_TEMPLATE_KEY.newsfeed : HOMEPAGE_TEMPLATE_KEY.default;
  } else {
    return template
  }
}
