import _ from "lodash";
import { hasCouponsForCheckout } from "../store/selectors";

const toFixedNumber = (number) => Number(number.toFixed(3));
const WHOLE_TOPPING_WEIGHT = 1;

const priceForSequence = (currentTopping, toppingCategory) => {

  const chosenToppingOptions = _.compact(
    _.map(toppingCategory.toppings, (topping) => {
      const chosenToppingOption =
        _.find(topping.toppingOptions, { chosen: true });
      return chosenToppingOption;
    }),
  );

  const currentChosenToppingOption =  _.find(currentTopping.toppingOptions, { chosen: true });
 
  const toppingIndex = _.indexOf(chosenToppingOptions, currentChosenToppingOption);
  const toppingWeightToIndex = _.sum(_.map(chosenToppingOptions, toppingOption => _.indexOf(chosenToppingOptions, toppingOption) <= toppingIndex ? _.get(toppingOption, "weight") : 0));

  const sequence = _.sortBy(toppingCategory.sequencePrices, "sequence");
  let lastFittingPrice = 0.0;
  for (let index = 1; index <= _.ceil(toppingWeightToIndex); index++) {
    const foundSequence = _.first(_.filter(sequence, s => s.sequence >= index));
    if (foundSequence){
      lastFittingPrice = foundSequence.price;
    }
  }
  return lastFittingPrice;
}

export const priceForSequenceOption = (orderItemComponentOption, orderItemComponent) => {
  if (!_.get(orderItemComponent, "sequencePrices") || _.isEmpty(_.get(orderItemComponent, "sequencePrices"))){
    return 0.0;
  }

  const chosenOptions = orderItemComponent.chosenOrderItemComponentOptions;
 
  let optionIndex = _.indexOf(chosenOptions, _.get(orderItemComponentOption, "id")) + 1;

  if (optionIndex == 0){
      optionIndex = chosenOptions.length + 1;
  }

  const sequence = _.sortBy(orderItemComponent.sequencePrices, "sequence");
  let lastFittingPrice = 0.0;
  for (let index = 1; index <= optionIndex; index++) {
    const foundSequence = _.first(_.filter(sequence, s => s.sequence >= index));
    if (foundSequence){
      lastFittingPrice = foundSequence.price;
    }
  }
  return lastFittingPrice;
}

export const calcToppingOptionPrice = (
  topping,
  toppingCategory,
  multipliers,
  { posInnerMenuId },
) => {

  const sequencePrice = toppingCategory.sequencePrices ? priceForSequence(topping, toppingCategory) : 0;
  const basePrice = getToppingBasePrice(toppingCategory, {
    posInnerMenuId,
  }) + sequencePrice;

  const defaultMultiplierIndex = _.findIndex(multipliers, "defaultMultiplier");

  const defaultMultiplierId =
    defaultMultiplierIndex > -1 ? multipliers[defaultMultiplierIndex] : null;
  const specificallyIncludedToppingsDiscount = _.sum(
    _.map(_.filter(toppingCategory.toppings, "included"), (topping) => {
      const defaultToppingOption =
        _.find(topping.toppingOptions, { weight: 1 }) ||
        topping.toppingOptions[0];
      return defaultToppingOption.price;
    }),
  );
  const categoryIncludedDiscount =
    specificallyIncludedToppingsDiscount +
    (toppingCategory.maxNumOfMarkedOptionsIncluded > 0
      ? toFixedNumber(toppingCategory.maxNumOfMarkedOptionsIncluded * basePrice)
      : 0);

  const calcUnusedDiscount = (toppingOption) => {
    if (categoryIncludedDiscount > 0) {
      const chosenToppings = _.filter(toppingCategory.toppings, (topping) =>
        _.find(topping.toppingOptions, "chosen"),
      );

      const chosenToppingOptions = _.map(chosenToppings, (topping) => {
        const chosenToppingOption = _.find(topping.toppingOptions, "chosen");

        const priceEffectiveMult = _.isEmpty(multipliers)
          ? chosenToppingOption.weight
          : chosenToppingOption.weight *
            _.get(
              _.find(
                multipliers,
                ({ id }) =>
                  id ===
                  (chosenToppingOption.multiplierId || defaultMultiplierId),
              ),
              "priceMult",
              1,
            );

        return {
          ...topping,
          weight: chosenToppingOption.weight,
          priceEffectiveMult,
          multiplierId: _.isEmpty(multipliers)
            ? null
            : chosenToppingOption.multiplierId || defaultMultiplierId,
        };
      });

      const orderedchosenToppings = _.orderBy(
        chosenToppingOptions,
        ["priceEffectiveMult", "weight", "included", "chosenTime"],
        ["desc", "desc", "asc", "desc"],
      );

      const toppingOptionIndex = toppingOption.chosen
        ? _.findIndex(orderedchosenToppings, (orderedTopping) =>
            _.find(
              orderedTopping.toppingOptions,
              (option) => option.posItemId === toppingOption.posItemId,
            ),
          )
        : -1;

      const leftDiscount =
        toppingOptionIndex <= 0
          ? categoryIncludedDiscount
          : _.reduce(
              _.take(orderedchosenToppings, toppingOptionIndex),
              (currentLeftDiscount, chosenTopping, index) => {
                if (currentLeftDiscount <= 0) {
                  return 0;
                }

                const multiplier =
                  chosenTopping.multiplierId &&
                  _.find(
                    multipliers,
                    (multiplier) =>
                      multiplier.id === chosenTopping.multiplierId,
                  );

                const chosenToppingOption = _.find(
                  chosenTopping.toppingOptions,
                  "chosen",
                );

                const effectivePriceMultiplier = multiplier
                  ? multiplier.priceMult * chosenToppingOption.weight
                  : chosenToppingOption.weight;

                const chosenToppingPrice = toFixedNumber(
                  effectivePriceMultiplier *
                    (basePrice + chosenToppingOption.price || 0),
                );

                // TODO: support additional Price for topping option
                // This is not in use as for 03/2020)

                currentLeftDiscount = toFixedNumber(
                  currentLeftDiscount - chosenToppingPrice,
                );
                return currentLeftDiscount;
              },
              categoryIncludedDiscount,
            );
      return leftDiscount <= 0 ? 0 : leftDiscount;
    }
    return 0;
  };

  const calcToppingOptionPriceForSelectedMultiplier = (
    toppingOption,
    selectedMultiplierIndex,
  ) => {
    const effectiveSelectedMultiplierIndex =
      selectedMultiplierIndex === -1 || !_.isNumber(selectedMultiplierIndex)
        ? defaultMultiplierIndex
        : selectedMultiplierIndex;

    const multiplier =
      Array.isArray(multipliers) &&
      effectiveSelectedMultiplierIndex &&
      multipliers[effectiveSelectedMultiplierIndex]
        ? multipliers[effectiveSelectedMultiplierIndex].priceMult
        : 1;

    const additionalPriceForToppingOption =
      getToppingBasePrice(toppingOption, { posInnerMenuId }) || 0;

    const leftDiscount = calcUnusedDiscount(toppingOption);

    const toppingOptionPrice =
      (toppingOption.weight * basePrice + additionalPriceForToppingOption) *
      multiplier;

    return Math.max(toppingOptionPrice - leftDiscount, 0);
  };
  return calcToppingOptionPriceForSelectedMultiplier;
};

export const isRadioButtonGroup = (orderItemComponent) => {
  const {
    maxNumOfMarkedOptionsIncluded,
    minNumOfMarkedComponentOptions,
    maxNumOfMarkedOptions,
    priceAdditional,
  } = orderItemComponent;

  return (
    !priceAdditional &&
    minNumOfMarkedComponentOptions === 1 &&
    maxNumOfMarkedOptionsIncluded === 1
  );
};

export const shouldEnableMultiSelect = (orderItemComponent) => {
  const {
    maxNumOfMarkedOptionsIncluded,
    minNumOfMarkedComponentOptions,
    maxNumOfMarkedOptions,
    priceAdditional,
  } = orderItemComponent;

  return (
    !(maxNumOfMarkedOptionsIncluded === 1 && !priceAdditional) &&
    _.get(orderItemComponent, "enableMultiSelectOption")
  );
};

export const getFirstComponentWithError = (menuItemOrderData) => {
  const firstComponentWithError = _.find(
    menuItemOrderData.orderItemComponents,
    (component) => {
      if (_.isEmpty(component.componentOptions)) {
        return false;
      }
      if (
        component.parentComponentTitle &&
        !isParentComponentChosen(
          menuItemOrderData.orderItemComponents,
          component.parentComponentTitle,
          component.parentOptionIds,
        )
      ) {
        return false;
      }
      return isRadioButtonGroup(component)
        ? !component.chosenOrderItemComponentOptions ||
            (Array.isArray(component.chosenOrderItemComponentOptions) &&
              component.chosenOrderItemComponentOptions.length === 0)
        : component.minNumOfMarkedComponentOptions > 0 &&
            (_.isEmpty(component.chosenOrderItemComponentOptions) ||
              component.chosenOrderItemComponentOptions.length <
                component.minNumOfMarkedComponentOptions);
    },
  );
  if (firstComponentWithError) {
    return firstComponentWithError;
  }
  if (menuItemOrderData.toppingsComponent) {
    const toppingCategoryWithError = _.find(
      menuItemOrderData.toppingsComponent.toppingsComponentCategories,
      (toppingCategory) => {
        if (
          toppingCategory.minNumOfMarkedComponentOptions > 0 &&
          !_.isEmpty(toppingCategory.toppings)
        ) {
          const categorySumSelectedToppings = _.sumBy(
            _.filter(
              _.flatMap(toppingCategory.toppings, "toppingOptions"),
              "chosen",
            ),
            "weight",
          );

          if (
            categorySumSelectedToppings <
            toppingCategory.minNumOfMarkedComponentOptions
          ) {
            return true;
          }
        }
        return false;
      },
    );
    if (toppingCategoryWithError) {
      return { title: toppingCategoryWithError.title, isToppingCategory: true };
    }
  }
  return null;
};

export const getSizeOptionPrice = (
  sizeOption,
  { posInnerMenuId, servingOptionId },
) => {
  const sizeOptionsForServingOption = _.find(
    _.get(sizeOption, "menuItemServingOptions.servingOptions"),
    { servingOptionId },
  );

  if (!sizeOptionsForServingOption) {
    return 0;
  }

  const sizeOptionForServingOption =
    sizeOptionsForServingOption.sizeOptions.length &&
    sizeOptionsForServingOption.sizeOptions[0];

  if (!sizeOptionForServingOption) {
    return 0;
  }

  if (
    posInnerMenuId &&
    !_.isEmpty(sizeOptionForServingOption.posInnerMenuSpecificPrices)
  ) {
    const innerMenuItem = _.find(
      sizeOptionForServingOption.posInnerMenuSpecificPrices,
      { posInnerMenuId },
    );
    if (innerMenuItem && _.isNumber(innerMenuItem.price)) {
      return innerMenuItem.price;
    }
    return sizeOptionForServingOption.price;
  }

  return sizeOptionForServingOption.price;
};

export const getToppingBasePrice = (toppingCategory, { posInnerMenuId }) => {
  const posInnerMenuItem =
    posInnerMenuId &&
    Array.isArray(toppingCategory.posInnerMenuSpecificPrices) &&
    _.find(toppingCategory.posInnerMenuSpecificPrices, { posInnerMenuId });

  const toppingBasePrice =
    posInnerMenuItem && _.isNumber(posInnerMenuItem.price)
      ? posInnerMenuItem.price
      : toppingCategory.price || 0;

  return toppingBasePrice;
};

const isParentComponentHeirarchyChosen = (currentComponent, allComponents) => {
  if (!_.get(currentComponent, "parentComponentTitle")){
    return true;
  }
  const parent = _.first(_.filter(allComponents, component => {
    return _.get(component, "title") === _.get(currentComponent, "parentComponentTitle");
  }));

  if (_.isEmpty(_.get(parent, "chosenOrderItemComponentOptions")) ||
    (_.get(currentComponent, "parentOptionIds") && _.isEmpty(_.intersection(_.get(parent, "chosenOrderItemComponentOptions"), _.get(currentComponent, "parentOptionIds"))))){
    return false;
  }

  const retVal = isParentComponentHeirarchyChosen(parent, allComponents);
  return retVal;
}

export const getPrice = (
  menuItemOrderData,
  { multipliers, servingOptionId, posInnerMenuId },
) => {
  const sumPrice = ({
    menuItemServingOptions,
    orderItemComponents,
    toppingsComponent,
  }) => {
    const currentServingOption =
      _.find(menuItemServingOptions.servingOptions, { servingOptionId }) ||
      _.find(menuItemServingOptions.servingOptions, {
        servingOptionType: "pickup",
      }) ||
      menuItemServingOptions.servingOptions[0];

    const chosenSizeOption = currentServingOption
      ? currentServingOption.sizeOptions.length === 1
        ? currentServingOption.sizeOptions[0]
        : menuItemServingOptions.selectedServingOption &&
          menuItemServingOptions.selectedServingOption.selectedMenuSizeId
        ? _.find(currentServingOption.sizeOptions, {
            menuSizeId:
              menuItemServingOptions.selectedServingOption.selectedMenuSizeId,
          })
        : { price: 0 }
      : { price: 0 };

    const currentSizeOption = { price: 0, ...chosenSizeOption };

    const posInnerMenuItem = _.find(
      currentSizeOption.posInnerMenuSpecificPrices,
      { posInnerMenuId },
    );

    const basePrice =
      posInnerMenuItem && _.isNumber(posInnerMenuItem.price)
        ? posInnerMenuItem.price
        : currentSizeOption.price || 0;

    // pre union all chosen component options to optimize searching chosen parent component options.
    const chosenComponentsOptions = _.union(
      ..._.map(orderItemComponents, "chosenOrderItemComponentOptions"),
    );

    const componentsAdditionalPrice = orderItemComponents.reduce(
      (sum, orderItemComponent) => {
        const componentPosInnerMenuItem = _.find(
          orderItemComponent.posInnerMenuSpecificPrices,
          { posInnerMenuId },
        );

        const priceAdditional =
          componentPosInnerMenuItem &&
          _.isNumber(componentPosInnerMenuItem.price)
            ? componentPosInnerMenuItem.price
            : orderItemComponent.priceAdditional || 0;

        const hasParentComponentChosenOrDoesNotHaveParent =
          isParentComponentHeirarchyChosen(orderItemComponent, orderItemComponents);
          // _.isEmpty(orderItemComponent.parentOptionIds) ||
          // !_.isEmpty(
          //   _.intersection(
          //     chosenComponentsOptions,
          //     orderItemComponent.parentOptionIds,
          //   ),
          // );

        if (
          hasParentComponentChosenOrDoesNotHaveParent &&
          Array.isArray(orderItemComponent.chosenOrderItemComponentOptions) &&
          orderItemComponent.chosenOrderItemComponentOptions.length > 0
        ) {
          const componentOptionsById = _.keyBy(
            orderItemComponent.orderItemComponentOptions,
            "orderItemComponentOptionId",
          );

          const chosenOptionsPrice = orderItemComponent.chosenOrderItemComponentOptions.reduce(
            (chosenOptionsPriceSum, chosenOptionId) => {
              const chosenOption = componentOptionsById[chosenOptionId];
              if (chosenOption) {
                const chosenOptionPosInnerMenuItem = _.find(
                  chosenOption.posInnerMenuSpecificPrices,
                  { posInnerMenuId },
                );

                const chosenOptionPrice =
                  chosenOptionPosInnerMenuItem &&
                  _.isNumber(chosenOptionPosInnerMenuItem.price)
                    ? chosenOptionPosInnerMenuItem.price
                    : chosenOption.price;

                const indexInChosen = _.indexOf(
                  orderItemComponent.chosenOrderItemComponentOptions,
                  chosenOptionId,
                );

                const showPriceAdditional =
                  orderItemComponent.chosenOrderItemComponentOptions.length >=
                    orderItemComponent.maxNumOfMarkedOptionsIncluded &&
                  (indexInChosen >=
                    orderItemComponent.maxNumOfMarkedOptionsIncluded ||
                    indexInChosen === -1);

                const price =
                  (chosenOptionPrice || 0) +
                  ((showPriceAdditional && priceAdditional) || 0) +
                  (priceForSequenceOption({id: chosenOptionId}, orderItemComponent) || 0);

                return chosenOptionsPriceSum + price;
              }

              return chosenOptionsPriceSum;
            },
            0,
          );

          return sum + chosenOptionsPrice;
        }

        return sum;
      },
      0,
    );

    const hasToppings =
      toppingsComponent &&
      Array.isArray(toppingsComponent.toppingsComponentCategories);

    const toppingsAdditionalPrice = hasToppings
      ? _.sumBy(
          toppingsComponent.toppingsComponentCategories,
          (toppingCategory) => {
            const posInnerMenuToppingCategory =
              Array.isArray(toppingCategory.posInnerMenuSpecificPrices) &&
              _.find(toppingCategory.posInnerMenuSpecificPrices);

            const categoryBasePrice =
              posInnerMenuToppingCategory &&
              _.isNumber(posInnerMenuToppingCategory.price)
                ? posInnerMenuToppingCategory.price
                : toppingCategory.price;

            const categorySelectedToppingsPrice = _.sumBy(
              toppingCategory.toppings,
              (topping) => {
                const toppingSelectedOptionsSum = _.sumBy(
                  _.filter(
                    topping.toppingOptions,
                    (toppingOption) => toppingOption.chosen,
                  ),
                  (toppingOption) => {
                    const toppingOptionSelectedMultiplierIndex = _.findIndex(
                      multipliers,
                      { id: toppingOption.multiplierId },
                    );
                    const toppingOptionPrice = calcToppingOptionPrice(
                      topping,
                      toppingCategory,
                      multipliers,
                      { posInnerMenuId },
                    )(toppingOption, toppingOptionSelectedMultiplierIndex);
                    return toppingOptionPrice;
                  },
                );
                return toppingSelectedOptionsSum;
              },
            );
            return categorySelectedToppingsPrice;
          },
        )
      : 0;

    return (
      (menuItemOrderData.count || 1) *
      (basePrice + componentsAdditionalPrice + toppingsAdditionalPrice)
    );
  };
  if (menuItemOrderData.optionsOrderData) {
    if (menuItemOrderData.selectedSizeId) {
      const selectedMenuItemOrderData = _.find(
        menuItemOrderData.optionsOrderData,
        { id: menuItemOrderData.selectedSizeId },
      );
      return sumPrice(selectedMenuItemOrderData);
    }
    return null;
  }
  return sumPrice(menuItemOrderData);
};

export const isParentComponentChosen = (
  orderItemComponents,
  parentComponentTitle,
  parentOptionIds,
) => {
  if (!Boolean(parentComponentTitle)){
    return true;
  }
  const parentComponent = _.find(orderItemComponents, {
    title: parentComponentTitle,
  });
  if (_.isEmpty(parentOptionIds)) {
    return (
      Boolean(parentComponent) &&
      Boolean(parentComponent.chosenOrderItemComponentOptions.length) &&
      isParentComponentChosen(orderItemComponents, _.get(parentComponent, "parentComponentTitle"),_.get(parentComponent, "parentOptionIds"))
    );
  }
  return (
    Boolean(parentComponent) &&
    !_.isEmpty(
      _.intersection(
        parentComponent.chosenOrderItemComponentOptions,
        parentOptionIds,
      ),
    )
    &&
    isParentComponentChosen(orderItemComponents, _.get(parentComponent, "parentComponentTitle"),_.get(parentComponent, "parentOptionIds"))
  );
};

export const toConfiguredMenuItemOrderData = ({
  remark,
  count = 1,
  selectedSizeId,
  sourceItem,
  ...menuItemOrderData
}) => {

  const itemData = menuItemOrderData.optionsOrderData
    ? _.find(menuItemOrderData.optionsOrderData, { id: selectedSizeId })
    : menuItemOrderData;

  const configuredMenuItemOrderData = {
    title: itemData.item,
    id: itemData.id,
    menuItemId: itemData.menuItemId,
    menuItemServingOptions: itemData.menuItemServingOptions,
    ingredients: menuItemOrderData.ingredients,
    orderItemComponents: _.map(
      _.filter(itemData.orderItemComponents, (orderItemComponent) =>
        orderItemComponent.parentComponentTitle
          ? isParentComponentChosen(
              itemData.orderItemComponents,
              orderItemComponent.parentComponentTitle,
              orderItemComponent.parentOptionIds,
            )
          : true,
      ) || [],
      (o) =>
        _.pick(
          o,
          "title",
          "chosenOrderItemComponentOptions",
          "index",
          "componentOptions",
        ),
      "chosenOrderItemComponentOptions",
    ),

    toppingsComponent: {
      toppingsComponentCategories: _.map(
        // one can also add a filter only the categories with selected options
        (itemData.toppingsComponent &&
          itemData.toppingsComponent.toppingsComponentCategories) ||
          [],
        ({ title, toppings }) => ({
          title,
          toppings: _.filter(
            _.map(toppings, ({ toppingOptions, title }) => ({
              title,
              toppingOptions: _.filter(
                toppingOptions,
                (option) => _.get(option, "chosen") || _.get(option, "edited"),
              ),
            })),
            ({ toppingOptions }) =>
              _.some(
                toppingOptions,
                (option) => _.get(option, "chosen") || _.get(option, "edited"),
              ),
          ),
        }),
      ),
    },
  };
  const itemSelectorTitle = _.first(_.get(menuItemOrderData, "sizeOptions"));
  return {
    ...configuredMenuItemOrderData,
    remark,
    count,
    sourceItem,
    minimumRequiredCount: menuItemOrderData.minimumRequiredCount,
    maximumAllowedCount: menuItemOrderData.maximumAllowedCount,
  };
};

export const itemHasSelectedServingSize = ({ configuredMenuItemOrderData }) =>
  configuredMenuItemOrderData.menuItemServingOptions &&
  configuredMenuItemOrderData.menuItemServingOptions.selectedServingOption &&
  configuredMenuItemOrderData.menuItemServingOptions.selectedServingOption
    .selectedMenuSizeId &&
  configuredMenuItemOrderData.menuItemServingOptions.selectedServingOption
    .selectedServingOptionId;

export const checkoutItemToMenuItemConfiguration = (item, menuData) => {
  const items = _.flatMap(menuData, "items");

  const itemWithSizes = !_.isEmpty(
    _.filter(
      items,
      (itemToFilter) =>
        !_.isEmpty(itemToFilter.sizeOptionsIds) &&
        _.includes(
          itemToFilter.sizeOptionsIds,
          item.configuredMenuItemOrderData.menuItemId,
        ),
    ),
  );

  const { configuredMenuItemOrderData, count, remark } = item;

  const orderItemComponents = _.isEmpty(
    configuredMenuItemOrderData.orderItemComponents,
  )
    ? []
    : _.map(
        _.filter(
          configuredMenuItemOrderData.orderItemComponents,
          (orderItemComponent) =>
            !_.isEmpty(orderItemComponent.chosenOrderItemComponentOptions),
        ),
        ({ chosenOrderItemComponentOptions, title }) => ({
          chosenOrderItemComponentOptions,
          title,
        }),
      );
  const removedIngredients = _.isEmpty(configuredMenuItemOrderData.ingredients)
    ? []
    : _.map(
        _.filter(
          configuredMenuItemOrderData.ingredients,
          ({ isSelected }) => !isSelected,
        ),
        "name",
      );

  const selectedMenuSizeId =
    !itemWithSizes &&
    itemHasSelectedServingSize(item) &&
    configuredMenuItemOrderData.menuItemServingOptions.selectedServingOption
      .selectedMenuSizeId;

  const toppings = _.fromPairs(
    _.flatMap(
      _.get(
        configuredMenuItemOrderData,
        "toppingsComponent.toppingsComponentCategories",
      ),
      (toppingsComponentCategory) => {
        const { toppings, title: categoryTitle } = toppingsComponentCategory;

        const selectedToppings = _.map(toppings, (topping) => {
          const { title } = topping;
          const { position, weight, chosen, multiplierId } =
            _.first(_.filter(topping.toppingOptions, "chosen")) || {};
          return [
            `${categoryTitle}_${title}`,
            { position: chosen ? position : null, weight, multiplierId },
          ];
        });
        return selectedToppings;
      },
    ),
  );

  const selectedSizeId = itemWithSizes && item.configuredMenuItemOrderData.id;

  return {
    count,
    remark,
    removedIngredients,
    orderItemComponents,
    toppings,
    ...(selectedMenuSizeId && { selectedMenuSizeId }),
    ...(selectedSizeId && { selectedSizeId }),
  };
};

export const toppingToShortString = (topping, multipliers, T) => {
  const selectedToppingOption = _.find(topping.toppingOptions, "chosen");

  if (!selectedToppingOption) {
    return;
  }

  const multiplierName =
    selectedToppingOption.multiplierId && !_.isEmpty(multipliers)
      ? _.get(
          _.find(multipliers, { id: selectedToppingOption.multiplierId }),
          "name",
        )
      : null;

  if (selectedToppingOption.price) {
    if (selectedToppingOption.weight === 1) {
      return `${multiplierName ? `${T(multiplierName)} ` : ""}${T(
        topping.title,
      )}${!topping.included ? `($${selectedToppingOption.price})` : ""}`;
    } else if (selectedToppingOption.weight === 0.5) {
      return `${multiplierName ? `${T(multiplierName)} ` : ""} ${
        topping.title
      } ${T("on the")} ${T(selectedToppingOption.position)} ${T("half")} ${
        !topping.included ? `($${selectedToppingOption.price})` : ""
      }`;
    } else {
      throw `Topping Weight: ${selectedToppingOption.weight} is not supported.`;
    }
  } else {
    if (selectedToppingOption.weight === 1) {
      return `${multiplierName ? `${T(multiplierName)} ` : ""}${T(
        topping.title,
      )}`;
    } else if (selectedToppingOption.weight === 0.5) {
      return `${multiplierName ? `${T(multiplierName)} ` : ""}${
        topping.title
      } ${T("on the")} ${T(selectedToppingOption.position)} ${T("half")}`;
    } else {
      throw `Topping Weight: ${selectedToppingOption.weight} is not supported.`;
    }
  }
};

export const toppingCategoryChosenToShortString = (
  toppingCategory,
  multipliers,
  T,
) => {
  return _.join(
    _.compact(
      _.map(toppingCategory.toppings, (topping) =>
        toppingToShortString(topping, multipliers, T),
      ),
    ),
    ", ",
  );
};
