const get = require("lodash/get");
const axios = require("axios");

const returnData = (res) => {
  const printError = (res) =>
    console.error(
      `Failed to post: URL: ${get(res, "request.responseURL")}, statusText: ${
        res.statusText
      }, statusCode ${res.status}`,
    );

  if (res.status === 204) {
    printError(res);
    // return Promise.resolve(null);
  }
  if (res.status > 204) {
    printError(res);
    throw Error(`${res.statusText}: ${get(res, "request.responseURL")}`);
  }

  return res.data;
};

module.exports = (url, headers) => {
  const printError = (error) =>
    console.error(
      `GET ${url} Failed to get a response from server, headers: ${
        headers ? headers : "None"
      }, error: ${error}`,
    );
  if (headers) {
    return axios
      .get(url, { headers })
      .then(returnData)
      .catch((error) => {
        printError(error);
        throw error;
      });
  } else {
    return axios
      .get(url)
      .then(returnData)
      .catch((error) => {
        printError(error);
        throw error;
      });
  }
};
