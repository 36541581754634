const UPDATE = "UPDATE";

const STARTED = "STARTED";
const SUCCESS = "SUCCESS";
const FAILED = "FAILED";
const SET = "SET";
const RESET = "RESET";

// const updateTracker = {};

export function startAsyncUpdateAction({
  entityType,
  payload,
  sideEffect,
  normalize,
  retryTimes,
}) {
  return (dispatch, getState) => {
    // updateTracker[entityType] = updateTracker[entityType] || {
    // 	[payload.id]: -1
    // };
    //
    // const actionIndex = (updateTracker[entityType][payload.id] =
    // 	updateTracker[entityType][payload.id] + 1 || 0);
    dispatch({ type: `${UPDATE}_${entityType}_${STARTED}`, payload });
    return sideEffect()
      .then((res) => {
        dispatch({
          type: `${UPDATE}_${entityType}_${SUCCESS}`,
          payload: normalize(res),
        });
      })
      .catch((error) => {
        dispatch({
          type: `${UPDATE}_${entityType}_${FAILED}`,
          error: true,
          payload: error,
        });
      });
  };
}

export const withTimestamp = (
  { type, payload },
  timestamp = new Date().getTime(),
) => ({
  type,
  payload: { ...payload, timestamp },
});

export const getConstantsFor = (prefix) => ({
  STARTED: `${prefix}_${STARTED}`,
  SUCCESS: `${prefix}_${SUCCESS}`,
  FAILED: `${prefix}_${FAILED}`,
  SET: `${prefix}_${SET}`, //used to override from local source of truth.
  RESET: `${prefix}_${RESET}`, //used to reset errors and set initial state.
});
