import React from 'react';
const { createContext, useContext } = require("react");

export const BUILD_TARGETS = {
    WEBSITE: 'website',
    ORDERING: 'ordering',
}

export const TargetContext = createContext(BUILD_TARGETS.ORDERING);

export const useBuildTargetContext = () => useContext(TargetContext); 

export function ProvideTargetContext({ children, target }) {
    return (
        <TargetContext.Provider value={target}>
            {children}
        </TargetContext.Provider>
    )
} 