import React, { useEffect } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

const GOOGLE_MAPS_API_KEY = "AIzaSyDMFj-OseCoZgiq2mYCFYefmW5Uo-gCJ9A"; // TODO: to env. var.

const toGoogleLanguageCode = (languageCode) => {
  switch (languageCode) {
    case "he":
      return "iw";
    case "en_us":
    case "en":
    case "en_US":
      return "en";
    case "de":
      return "de";
    default:
      return "en";
  }
};

const GoogleMapsLoaderComponent = ({
  onLoaded,
  language,
  loadPlaces,
  apiKey,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey || GOOGLE_MAPS_API_KEY,
    language: toGoogleLanguageCode(language),
    libraries: loadPlaces ? ["places"] : undefined,
  });

  useEffect(() => {
    if (!isLoaded) return;

    onLoaded({ isLoaded });
  }, [isLoaded]);

  return null;
};

export default React.memo(GoogleMapsLoaderComponent);
