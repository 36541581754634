import noop from "lodash/noop";
import React from "react";
import * as styles from "./index.module.css";

export default ({ linkTitle, geoPoint = {}, time, T = noop }) => (
  <a
    href={`https://www.waze.com/ul?ll=${geoPoint.latitude}%2C${
      geoPoint.longitude
    }&pd_time=${time}&pd_title=${encodeURIComponent(
      linkTitle,
    )}&navigate=yes&zoom=16&utm_source=Partner&utm_campaign=Appfront`}
    target="_blank"
    className={styles.WazeButton}
  >
    {T("Drive here with Waze")}
  </a>
);
