import React from "react";
import Markdown from "react-markdown";
import RemarkBreaksPlugin from "remark-breaks";
import ExternalLink from "../ExternalLink";
import rehypeRaw from "rehype-raw";
import { makeStyles } from "../AppContainer/mui-theme";

export default ({ children, styles }) => {
  if (typeof children === "string") {
    const { classes } = useStyles(styles || {});

    const strippedEscape = children.replace(/\\n/g, "\n");
    return (
      <Markdown
        parserOptions={{ commonmark: true }}
        remarkPlugins={[RemarkBreaksPlugin]}
        rehypePlugins={[rehypeRaw]}
        linkTarget="_blank"
        renderers={{
          link: ExternalLink,
        }}
        className={classes.root}
      >
        {strippedEscape}
      </Markdown>
    );
  }

  return "Rich text should be markdown (MD) formatted or plain text";
};

const useStyles = makeStyles()((theme, styles) => ({
  root: styles
}));