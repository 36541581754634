import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function({ children, useReacptcha, recaptchaKey, ...props }) {
  console.log({ useReacptcha, recaptchaKey });
  return useReacptcha && recaptchaKey ? (
    <GoogleReCaptchaProvider
      reCaptchaKey={recaptchaKey}
      useRecaptchaNet={true}
      useEnterprise={true}
    >
      {children}
    </GoogleReCaptchaProvider>
  ) : (
    <>{children}</>
  );
}
