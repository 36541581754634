import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { isEmpty } from "lodash"  

export const cacheProps = {
  key: "mui",
  prepend: true,
};

export let muiCache;

export const createMuiCache = (appStyles = {}) => {
  if (!muiCache || !isEmpty(appStyles)) {
    const stylisPlugins = [prefixer];
    
    if (appStyles?.rtl) {
      stylisPlugins.push(rtlPlugin);
    }

    muiCache = createCache({
      ...cacheProps,
      stylisPlugins,
    });
  }
  return muiCache;
};
