import _ from 'lodash';

export const fillGiftsExpirationDate = gifts => {
  console.log('Called fill');
  return _.sortBy(
    _.map(gifts, gift => {
      const couponInstance = gift.couponInstance ? gift.couponInstance : gift;
      const wasSeen = couponInstance.firstSeenDate && true;

      if (couponInstance && couponInstance.coupon && couponInstance.coupon.couponValidity) {
        const couponExpirationDate = couponInstance.coupon.couponValidity.expireDate;
        if (
          !couponInstance.coupon.couponValidity.timerDuration ||
          couponInstance.coupon.couponValidity.timerDuration <= 0
        ) {
          return {
            ...gift,
            expirationDate: couponExpirationDate,
            wasSeen,
          };
        }
        const durationFinishDate =
          couponInstance.timerStartTime &&
          couponInstance.coupon.couponValidity.timerDuration &&
          couponInstance.timerStartTime + couponInstance.coupon.couponValidity.timerDuration * 1000;
        const expirationDate = !expirationDate
          ? durationFinishDate
          : !durationFinishDate
          ? couponExpirationDate
          : couponExpirationDate > durationFinishDate
          ? durationFinishDate
          : couponExpirationDate;
        return {
          ...gift,
          expirationDate,
          wasSeen,
        };
      }
      return { ...gift, wasSeen };
    }),
    gift => (gift.wasSeen ? gift.expirationDate : 0)
  );
};

export const getGiftRelevantBranchesIds = gift =>
  isPointBankCoupon(gift)
    ? _.get(gift, 'coupon.couponValidity.relevantBranchesIds')
    : _.get(gift, 'couponInstance.coupon.couponValidity.relevantBranchesIds');

export const isMoneyCoupon = coupon => Boolean(_.get(coupon, 'couponValue.offeredMoneyAmount'));

export const isGlobalDiscount = coupon =>
  Boolean(
    _.get(coupon, 'couponValue.offeredGlobalDiscount') === 'BASIC' && _.get(coupon, 'couponValue.discountPercent')
  );

export const isPointBankCoupon = gift => _.get(gift, 'type') === 'PointBankTradePolicy';

export const getCoupon = gift => (isPointBankCoupon(gift) ? gift.coupon : _.get(gift, 'couponInstance.coupon'));
