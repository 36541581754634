import { cloneDeep, filter, get, isEmpty, map, omit, pick } from "lodash";
import { createTransform } from "redux-persist";

// Define the transform function
export const orderTransform = createTransform(
  // Transform function to run when state is being persisted
  (inboundState, key) => {
    return {
      ...inboundState,
      orderItems: inboundState.orderItems.map(
        ({
          sourceItem,
          menuItemConfiguration,
          configuredMenuItemOrderData,
          ...rest
        }) => {
          const configuredMenuItemOrder = cloneDeep(
            omit(configuredMenuItemOrderData, [
              "menuItemServingOptions",
              "orderItemComponents",
              "sourceItem",
            ])
          );

          configuredMenuItemOrder.menuItemServingOptions = omit(get(
            configuredMenuItemOrderData,
            "menuItemServingOptions"
          ), ["servingOptions"]);

          configuredMenuItemOrder.orderItemComponents = filter(
            map(
              get(configuredMenuItemOrderData, "orderItemComponents"),
              (item) => omit(item, "componentOptions")
            ),
            ({ chosenOrderItemComponentOptions }) =>
              !isEmpty(chosenOrderItemComponentOptions)
          );

          return {
            id: sourceItem.id,
            // for subitems menuId points to categoryId
            categoryId: sourceItem.categoryId || sourceItem.menuId,
            menuItemConfiguration,
            configuredMenuItemOrderData: configuredMenuItemOrder,
            ...rest,
          };
        }
      ),
      checkoutResponse: {},
    };
  },
  // Transform function to run when state is being rehydrated
  (outboundState, key) => {
    return {
      ...outboundState,
      orderItems: outboundState.orderItems.map(({ id, ...rest }) => ({
        sourceItem: { id },
        ...rest,
      })),
      checkoutResponse: {},
      shouldSyncOrderItems: true,
    };
  },
  // Define which reducer this transform gets applied to.
  { whitelist: ["order"] }
);
