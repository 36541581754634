import classnames from "classnames";
import _ from "lodash";
import React from "react";
import * as styles from "./CardToppingSelect.module.scss";

class ToppingSelect extends React.Component {
  // not handling limit
  constructor(props) {
    super(props);
    const {
      children,
      checked = [],
      // selectedMultiplierIndex,
      limit,
      disabled,
    } = props;

    const checkedIndices = _.filter(
      checked.map((value) =>
        children.findIndex((c) => c.props.value === value),
      ),
      (index) => index !== -1,
    );

    this.state = {
      checkedIndices,
      disabled,
    };
  }

  // This is the case to handle late arriving props,
  // and set the state according to the value
  // as long as it's not disabled
  // not handling limit
  componentDidUpdate(prevProps) {
    const { checked, selectedMultiplierIndex, disabled } = this.props;
    if (!_.isEqual(checked, prevProps.checked)) {
      const children = this.props.children;
      const checkedIndices = _.filter(
        checked.map((value) =>
          children.findIndex((c) => c.props.value == value),
        ),
        (index) => index !== -1,
      );

      this.setState({ checkedIndices });
    }
  }

  onChange = (index) => {
    const {
      onChange,
      children,
      limit,
      disabled,
      selectedMultiplierIndex,
    } = this.props;
    const { checkedIndices } = this.state;
    const child = children[index];
    if (!child || disabled) return;

    if (limit === 1) {
      this.setState({ checkedIndices: [index] }, () => {
        onChange &&
          onChange({
            selected:
              this.state.checkedIndices.map(
                (index) => children[index].props.value,
              ) || [],
            selectedMultiplierIndex,
          });
      });
    }

    if (
      this.state.checkedIndices.length >= limit &&
      !_.includes(this.state.checkedIndices, index)
    )
      return;

    const nextCheckedIndices = _.xor(this.state.checkedIndices, [index]);

    this.setState({ checkedIndices: nextCheckedIndices }, () => {
      onChange &&
        onChange({
          selected:
            this.state.checkedIndices.map(
              (index) => children[index].props.value,
            ) || [],
          selectedMultiplierIndex,
        });
    });
  };

  renderChild = (child, index, checked) => {
    const { children, limit, pointColor, disabled, disabledColor } = this.props;
    const { checkedIndices } = this.state;

    return React.cloneElement(child, {
      index,
      disabled:
        (limit > 1 && !checked && checkedIndices.length >= limit) || disabled,
      pointColor,
      disabledColor,
      checked,
      showLabel: checkedIndices.length > 0,
      key: index,
      last: index === children.length - 1,
      onChange: this.onChange,
      ...child.props,
    });
  };

  onMultiplierChange = (selectedMultiplierIndex) => {
    const { onChange, children, disabled } = this.props;
    if (disabled) {
      return;
    }

    onChange &&
      onChange({
        selected:
          this.state.checkedIndices.map(
            (index) => children[index].props.value,
          ) || [],
        selectedMultiplierIndex,
      });
  };

  renderMultiplier = (multiplier, index, checked, last, disabled) => {
    const { pointColor, disabledColor, rtl } = this.props;

    const style = {
      display: "flex",
      flexGrow: 1,
      paddingTop: 6,
      paddingBottom: 6,
      alignItems: "center",
      justifyContent: "center",
      borderRight: (rtl ? index === 0 : last) || checked ? "none" : "1px solid",
      borderColor: disabled ? disabledColor : pointColor,
      color: checked ? "white" : disabled ? disabledColor : pointColor,
      background: checked
        ? disabled
          ? disabledColor
          : pointColor
        : "transparent",
    };

    return (
      <div
        key={index}
        style={style}
        onClick={() => this.onMultiplierChange(index)}
      >
        {multiplier.name}
      </div>
    );
  };

  render() {
    const { checkedIndices } = this.state;
    const {
      multipliers,
      selectedMultiplierIndex,
      limit,
      children,
      title,
      pointColor,
      disabledColor,
      disabled,
      rtl,
      ...props
    } = this.props;

    const primaryColor = pointColor;

    const style = {
      display: "inline-flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      // transition: "all 0.3s 0.5s ease-in-out",
      paddingBottom: 32, //disabled ? 0 : 32,
    };
    const titleBaseStyle = {
      display: "flex",
      flexGrow: 1,
      fontSize: "0.875rem",
    };
    const titleStyle = checkedIndices.length
      ? {
          ...titleBaseStyle,
          fontWeight: "bold",
          color: primaryColor,
        }
      : { ...titleBaseStyle, ...(disabled && { color: disabledColor }) };

    const multiplierContainerStyle = {
      display: "flex",
      flexGrow: 1,
      border: "1px solid",
      borderColor: primaryColor,
      marginBottom: 16,
    };

    return (
      <div>
        <div style={style} {...props} className="noselect">
          <span style={titleStyle}>{title}</span>
          {children.map
            ? children.map((child, index) =>
                this.renderChild(
                  child,
                  index,
                  _.includes(checkedIndices, index),
                  disabled,
                ),
              )
            : this.renderChild(
                children,
                0,
                _.includes(checkedIndices, 0),
                disabled,
              )}
        </div>
        {multipliers && checkedIndices.length > 0 && (
          <div style={multiplierContainerStyle}>
            {_.map(multipliers, (multiplier, index) => {
              return this.renderMultiplier(
                multiplier,
                index,
                selectedMultiplierIndex == index,
                index + 1 === multipliers.length,
                disabled,
                rtl,
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

class ToppingOption extends React.Component {
  getStyles = () => {
    const {
      last,
      padding,
      weight,
      rootColor,
      pointColor,
      disabledColor,
      label,
    } = this.props;

    return {
      root: {
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        color: rootColor || "#555",
        borderColor: rootColor || "#E0E0E0",
        padding: padding || 6,
        display: "flex",
        flexGrow: 0,
        position: "relative",
        marginBottom: 0,
        marginRight: !last ? (padding || 0) / 2 : 0,
      },
      label: {
        position: "absolute",
        transition: "all 0.2s ease-in-out",
        borderStyle: "none",
        padding: padding || 5,
        marginBottom: 0,
        marginRight: !last ? (padding || 5) / 2 : 0,
      },
      disabled: {
        color: disabledColor || "#e1e1e1",
        cursor: "not-allowed",
        borderColor: disabledColor || "#e1e1e1",
      },
      checked: {
        transition: "all 0.2s ease-in-out",
        borderColor: pointColor || "#8CB9FD",
        color: pointColor || "#8CB9FD",
        fontWeight: "bold",
      },
    };
  };

  onClick = () => {
    const { onChange, checked, index, disabled } = this.props;
    !disabled && onChange && onChange(index);
  };

  render() {
    const {
      checked,
      iconSize,
      iconInnerSize,
      rootColor,
      pointColor,
      children,
      disabled,
      disabledColor,
      padding,
      label,
      weight,
      position,
      showLabel,
      unfilled,
    } = this.props;
    const style = this.getStyles();
    const buttonStyle = _.assign(
      {},
      style.root,
      disabled ? style.disabled : checked ? style.checked : {},
    );
    const labelStyle = {
      position: "absolute",
      top: 50,
      left: 0,
      width: "100%",
      fontSize: "0.75rem",
      display: "flex",
      justifyContent: "center",
    };

    return (
      <div style={buttonStyle} onClick={this.onClick} className="noselect">
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ToppingIcon
            size={iconSize}
            weight={weight}
            position={position}
            innerSize={iconInnerSize}
            checked={checked}
            rootColor={rootColor}
            pointColor={pointColor}
            disabled={disabled}
            disabledColor={disabledColor}
            marginRight={padding || 10}
            unfilled={unfilled}
          />
        </div>
        {label && showLabel ? (
          <div className="noselect" style={labelStyle}>
            {label}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

class ToppingIcon extends React.Component {
  getStyles = () => {
    const {
      size,
      weight,
      position,
      innerSize,
      rootColor = "#9E9E9E",
      pointColor = "#8CB9FD",
      disabled,
      disabledColor = "#e1e1e1",
      marginRight,
    } = this.props;

    return {
      root: {
        transition: "all 0.2s ease-in-out",
        width: size || 10,
        height: size || 10,
        padding: 4,
        backgroundColor: "#fff",
        borderWidth: 2,
        borderRadius: 2,
        borderStyle: "solid",
        borderColor: disabled ? disabledColor : rootColor,
        marginRight: marginRight || 0,
      },
      checked: {
        transition: "all 0.2s ease-in-out",
        borderColor: disabled ? disabledColor : pointColor,
      },
      inner: {
        transition: "all 0.2s ease-in-out",
        width: innerSize || 10,
        height: innerSize || 10,
        background: disabled ? disabledColor : pointColor,
        borderRadius: 2,
      },
    };
  };

  render() {
    const {
      checked,
      pointColor,
      weight,
      position,
      disabled,
      disabledColor,
      unfilled,
    } = this.props;
    const style = this.getStyles();
    const iconStyle = _.assign(style.root, checked ? style.checked : {});
    const circleBaseStyle = {
      transform: position === "right" ? "rotate(-90deg)" : "rotate(90deg)",
    };
    const circleStyle = disabled
      ? { ...circleBaseStyle, color: disabledColor }
      : checked
      ? { ...circleBaseStyle, color: pointColor }
      : { ...circleBaseStyle, color: "#757575" };
    const baseSize = 28;
    return (
      <svg
        width={baseSize}
        height={baseSize}
        className={classnames(styles.CircleWrapper, "noselect")}
        style={circleStyle}
      >
        <circle
          r={baseSize / 2}
          cx={baseSize / 2}
          cy={baseSize / 2}
          className={classnames(styles.Circle, "noselect")}
          style={{
            strokeDasharray: weight * baseSize * 1.57 * 2,
            ...(unfilled && !checked && { stroke: "transparent" }),
          }}
        />
      </svg>
    );
  }
}

export const CardToppingSelect = (props) => (
  <ToppingSelect {...props} limit={1} />
);

export const CardToppingOption = (props) => <ToppingOption {...props} />;
