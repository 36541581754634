import { map } from "lodash";

export const getGtmHeadScript = (
  gtmId,
) => `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`;

export const getAwHeadScript = (awId) =>
  `https://www.googletagmanager.com/gtag/js?id=${awId}&l=gtagDataLayer`;

export const initGtagScript = (awIds) =>
  `gtag("js", new Date());${map(
    awIds,
    (awId) => `gtag("config", '${awId}');`,
  ).join("")}`;

export const gtagDataLayerInitScript = `window.gtagDataLayer = window.gtagDataLayer || [];function gtag(){gtagDataLayer.push(arguments);}`;

// gtag.js
export const fireConversionEvent = (trackingId) => {
  trackingId &&
    window.gtag &&
    window.gtag("event", "conversion", { send_to: trackingId });
};

const fireEvent = (event) => {
  if (window.dataLayer) {
    console.log("fired GTM event:", event);
    window.dataLayer.push(event);
  } else {
    console.log(
      "Failed to fire GTM event:",
      event,
      "Are you in dev mode? view item events only works on navigating to the page.",
    );
  }
};

// gtm;
export const firePurchaseEvent = (purchaseParams) => {
  const eventParams = {
    event: "purchase",
    ecommerce: purchaseParams,
  };
  fireEvent(eventParams);
};

export const fireBeginCheckout = (purchaseParams) => {
  const eventParams = {
    event: "begin_checkout",
    ecommerce: purchaseParams,
  };
  fireEvent(eventParams);
};

export const fireSelectItemEvent = (menuItem) => {
  const eventParams = {
    event: "select_item",
    ecommerce: {
      items: [
        {
          item_name: menuItem.name,
          item_id: menuItem.id,
          item_category: menuItem.categoryName,
          item_list_name: menuItem.categoryName,
          item_list_id: menuItem.categoryId,
          index: menuItem.index,
          price: menuItem.displayedPrice,
          currency: menuItem.currency,
        },
      ],
    },
  };
  fireEvent(eventParams);
};

export const fireViewItemEvent = (menuItemDetails) => {
  const eventParams = {
    event: "view_item",
    ecommerce: {
      items: [
        {
          item_name: menuItemDetails.itemName,
          item_id: menuItemDetails.menuItemId,
        },
      ],
    },
  };
  fireEvent(eventParams);
};

export const fireAddItemEvent = (menuItemDetails) => {
  const eventParams = {
    event: "add_to_cart",
    ecommerce: {
      items: [
        {
          item_name: menuItemDetails.itemName,
          item_id: menuItemDetails.menuItemId,
          price: menuItemDetails.price,
          currency: menuItemDetails.currency,
          quantity: menuItemDetails.quantity,
        },
      ],
    },
  };
  fireEvent(eventParams);
};

export const fireRemoveItemEvent = (removeMenuItemParams) => {
  const eventParams = {
    event: "remove_from_cart",
    ecommerce: {
      items: [
        {
          item_name: removeMenuItemParams.title,
          item_id: removeMenuItemParams.menuItemId,
          price: removeMenuItemParams.price,
          currency: removeMenuItemParams.currency,
          quantity: removeMenuItemParams.count,
        },
      ],
    },
  };
  fireEvent(eventParams);
};
