import { pick } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import * as styles from './WebsiteHeaderMenu.module.scss'
import { Link } from '../../utils/location';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const MENU_TYPE = {
    MOBILE: 'MOBILE',
    DESKTOP: 'DESKTOP',
}

const ITEM_TYPES = {
    LINK: 'link',
    BUTTON: 'button',
}

const createMenu = ({ orderUrl }, type = MENU_TYPE.DESKTOP) => [
    {
        href: '/',
        label: 'Home',
        type: ITEM_TYPES.LINK,
    },
    {
        href: '/menu',
        label: 'Menu',
        type: ITEM_TYPES.LINK,
    },
    {
        href: '/locations',
        label: 'Locations',
        type: ITEM_TYPES.LINK,
    },
    {
        href: '/about',
        label: 'About',
        type: ITEM_TYPES.LINK,
    },
    {
        href: '/catering',
        label: 'Catering',
        type: ITEM_TYPES.LINK,
    },
    {
        href: orderUrl,
        label: 'Online ordering',
        type: type === MENU_TYPE.DESKTOP
            ? ITEM_TYPES.BUTTON
            : ITEM_TYPES.LINK,
    },
];

function WebsiteHeaderMenu(props) {
    return (
        <nav className={styles.WebsiteHeaderMenu}>
            <WebsiteHeaderMenu.Mobile {...props} />
            <WebsiteHeaderMenu.Desktop {...props} />
        </nav>
    );
}

WebsiteHeaderMenu.ItemType = function ItemType({ T, type, href, label, appStyles = {}, style = {} }) {
    switch (type) {
        case ITEM_TYPES.LINK:
            return <Link
                to={href}
                style={{ color: appStyles.headerTextColor }}
            >
                {T(label)}
            </Link>
        // return <a style={{ color: appStyles.headerTextColor, }} href={href}>{T(label)}</a>
        case ITEM_TYPES.BUTTON:
            return (
                <a href={href} style={{
                    color: "white",
                    whiteSpace: 'nowrap',
                    backgroundColor: appStyles.actionColor,
                    ...pick(
                        appStyles.Button,
                        "color",
                        "background",
                        "backgroundColor",
                        "border",
                    ),
                    borderRadius: 50,
                    padding: "5px 10px",
                    ...appStyles.HeaderLoginButton,
                }}>{T(label)}</a>
            )
        default:
            return null;
    }
}

WebsiteHeaderMenu.Mobile = function WebsiteHeaderMenuMobile(props) {
    const { T, orderUrl, appStyles = {} } = props;

    const container = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const menu = useMemo(() => createMenu({orderUrl}, MENU_TYPE.MOBILE), [props]);

    const topOffset = useMemo(() => {
        const header = document.getElementById('header-line');
        if (!header) {
            return 0;
        }

        return header.offsetTop + header.offsetHeight;
    });

    const onToggle = () => setIsOpen(open => !open);

    useEffect(() =>
        document.addEventListener('click', ({ target }) => {
            const header = document.getElementById('header-line');
            const container = document.getElementById('mobile-nav-container');
            if (target && container.dataset.state === 'open' &&
                (container && header && !container.contains(target) && !header.contains(target))) {
                setIsOpen(false);
            }
        }),
        [],
    );

    return (
        <div style={{ position: 'relative' }}>
            <div className={styles.WebsiteHeaderMenuMobile}>
                <span style={{ cursor: 'pointer', fontSize: '20px' }} onClick={onToggle}>
                    {isOpen ? <CloseIcon/> : <MenuIcon />}
                </span>
            </div>
            <div id="mobile-nav-container" data-state={isOpen ? 'open' : 'close'} className={styles.MobileHeaderContainer} style={{ display: isOpen ? 'block' : 'none' }}>
                <ul className={styles.MobileHeaderList}>
                    {menu.map(item => (
                        <li key={item.href}>
                            <WebsiteHeaderMenu.ItemType {...item} appStyles={appStyles} T={T} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

WebsiteHeaderMenu.Desktop = function WebsiteHeaderMenuDesktop(props) {
    const { T, appStyles = {} } = props;
    const menu = useMemo(() => createMenu(props), [props]);

    return (
        <div className={styles.WebsiteHeaderMenuDesktop}>
            <ul>
                {menu.map(item => (
                    <li key={item.href}>
                        <WebsiteHeaderMenu.ItemType {...item} appStyles={appStyles} T={T} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default WebsiteHeaderMenu;
