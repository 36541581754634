import { isOverNightSession, WEEKDAY_TO_INDEX } from './futureOrder';
import _ from 'lodash';
import * as CONSTANTS from './constants';

const isEmptyOvernightSession = timeFrame =>
  timeFrame.isOverNightSession &&
  timeFrame.startHour === 0 &&
  timeFrame.startMinute === 0 &&
  timeFrame.endHour === 0 &&
  timeFrame.endMinute === 0;

export const reformatTimeFrames = (option = {}) => {
  if (option.timeFrames) {
    const additionalTimeFrames = [];
    const timeFrames = _.concat(
      _.map(option.timeFrames, timeFrame => {
        const day = WEEKDAY_TO_INDEX[timeFrame.weekday];
        if (isOverNightSession(timeFrame)) {
          additionalTimeFrames.push({
            ..._.omit(timeFrame, 'weekday', 'timeZoneStr'),
            startHour: 0,
            startMinute: 0,
            day: (day + 1) % 7,
            isOverNightSession: true,
          });

          return {
            ..._.omit(timeFrame, 'weekday', 'timeZoneStr'),
            endHour: CONSTANTS.OVERNIGHT_SESSION_MIDNIGHT,
            endMinute: 0,
            day,
            hasOvernightSession: true,
          };
        }
        return { ..._.omit(timeFrame, 'weekday', 'timeZoneStr'), day };
      }),
      additionalTimeFrames
    );
    const filteredTimeFrame = _.filter(timeFrames, timeFrame => !isEmptyOvernightSession(timeFrame));
    return { ...option, timeFrames };
  }
  return option;
};

const mod = (x, n) => ((x % n) + n) % n;

export const branchesAvailabilityReformatTimeFrames = branchesAvailability => {
  return _.keyBy(
    _.map(branchesAvailability, branchAvailability => {
      const branchAvailabilityOpenHours = _.get(branchAvailability, 'detailedOpenHours.openHours');
      const openHours = _.map(branchAvailabilityOpenHours, locationAvailability => {
        const start = _.map(_.split(locationAvailability.openHour, ':'), hour => _.toInteger(hour));
        const end = _.map(_.split(locationAvailability.closeHour, ':'), hour => _.toInteger(hour));

        const isOverNightSession = Boolean(
          start[0] === 0 &&
            start[1] === 0 &&
            _.find(branchAvailabilityOpenHours, {
              day: mod(locationAvailability.day - 1, 7),
              closeHour: '24:00',
            })
        );
        return {
          day: locationAvailability.day,
          startHour: start[0],
          startMinute: start[1],
          endHour: end[0],
          endMinute: end[1],
          ...(isOverNightSession && { isOverNightSession }),
          ...(end[0] === CONSTANTS.OVERNIGHT_SESSION_MIDNIGHT && { hasOvernightSession: true }),
        };
      });

      const filteredOpenHours = _.filter(openHours, openHour => !isEmptyOvernightSession(openHour));
      return { ...branchAvailability, detailedOpenHours: { openHours: filteredOpenHours } };
    }),
    'branchId'
  );
};
