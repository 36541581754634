import { get, post } from "../utils/rest";
import SERVICE from "./constants";

export default class BagAPI {
  constructor(user, businessId) {
    this.businessId = businessId || process.env.GATSBY_BUSINESS_ID;
    if (user && user.userId) {
      this.user = user;
      this.authHeaders = {
        SESSION_ID: user.secretGUID,
        BUSINESS_ID: this.businessId,
        USER_ID: user.userId,
        MAC_ADDRESS: user.uniqueIdentifier,
      };
    }
  }

  updateOrderAndBeginPayment = (order) => {
    if (this.user && this.user.userId) {
      return post(
        `${SERVICE.ORDER}/updateOrderAndBeginPayment`,
        {
          userId: this.user && this.user.userId,
          ...order,
          businessId: this.businessId,
          platform: SERVICE.PLATFORM_WEB,
        },
        this.authHeaders,
      );
    } else {
      return post(`${SERVICE.ORDER}/updateOrderAndBeginPayment`, {
        ...order,
        businessId: this.businessId,
        platform: SERVICE.PLATFORM_WEB,
      });
    }
  };

  approveCashPayment = (approvalRequest) =>
    this.user && this.user.userId
      ? post(
          `${SERVICE.ORDER}/approveCommittingCompoundPaymentOrder`,
          { ...approvalRequest, os: "web" },
          this.user.authHeaders,
        )
      : post(`${SERVICE.ORDER}/approveCashPaymentOrderInsecure`, {
          ...approvalRequest,
          os: "web",
        });

  openPayment = (openPaymentRequest) => {
    if (this.user && this.user.userId) {
      return post(
        `${SERVICE.PAYMENT}/openPayment`,
        openPaymentRequest,
        this.authHeaders,
      );
    } else {
      return post(`${SERVICE.PAYMENT}/openPaymentInsecure`, openPaymentRequest);
    }
  };

  approveCreditCardPayment = (paymentRequest) =>
    this.user && this.user.userId ? post(
          //replace to compound
          `${SERVICE.ORDER}/approveCommittingCompoundPaymentOrder`,
          { ...paymentRequest, userId: this.user.userId, os: "web" },
          this.user.authHeaders,
        )
      : post(`${SERVICE.ORDER}/approveOneTimePaymentOrderInsecure`, {
          ...paymentRequest,
          os: "web",
        });

  getCouponBatches = (purchaseEventId) =>
    get(
      `${
        SERVICE.COUPON
      }/getCouponBatchesForPurchaseEvent/purchaseEventId=${purchaseEventId}`,
    );

  deactivateAllCoupons = () =>
    post(
      `${SERVICE.COUPON}/deactivateAllCouponsForUser`,
      { userId: this.user.userId, bussinesId: this.businessId },
      this.authHeaders,
    );

  addTestCouponToUser = (couponId) =>
    post(`${SERVICE.COUPON}/addTestCouponInstanceToUser`, {
      couponId,
      userId: this.user.userId,
    });

  getPaymentMethods = (payload) => {
    if (this.user && this.user.loggedIn) {
      return post(
        `${SERVICE.PAYMENT}/getPaymentMethods`,
        {
          ...payload,
          businessId: this.businessId,
          userId: this.user.userId,
        },
        this.authHeaders,
      );
    }
    throw "getPaymentMethods requires logged-in user credentials";
  };

  confirmPayment = (confirmPaymentRequest) => {
    if (this.user && this.user.loggedIn) {
      return post(
        `${SERVICE.PAYMENT}/confirmPayment`,
        confirmPaymentRequest,
        this.authHeaders,
      );
    } else {
      return post(
        `${SERVICE.PAYMENT}/confirmPaymentInsecure`,
        confirmPaymentRequest,
      );
    }
  };

  getHashForTicket = (hashRequest) => {
    return post(
      `${SERVICE.PAYMENT}/getHashForTicket`,
      hashRequest,
      this.authHeaders,
    );
  };

  savePaymentMethod = (savePaymentMethodRequest) => {
    return post(
      `${SERVICE.PAYMENT}/savePaymentMethod`,
      savePaymentMethodRequest,
      this.authHeaders,
    );
  };

  removePaymentMethod = (removePaymentMethodRequest) => {
    return post(
      `${SERVICE.PAYMENT}/removePaymentMethod`,
      removePaymentMethodRequest,
      this.authHeaders,
    );
  };

  getPendingPurchaseEvents = () =>
    get(
      `${SERVICE.POS}/getPendingPurchaseEvents/userId=${
        this.user.userId
      }&businessId=${this.businessId}`,
      this.authHeaders,
    );

  getStatusOfPurchaseEvent = (purchaseEventId) =>
    get(
      `${SERVICE.POS}/getPurchaseEvent/purchaseEventId=${purchaseEventId}`,
      this.authHeaders,
    );

  getChecksForUser = () =>
    get(`${SERVICE.SCAN_AND_PAY}/getOpenChecksForUser`, this.authHeaders);

  getCheckDetails = (checkId) =>
    get(
      `${SERVICE.SCAN_AND_PAY}/getCheck?checkId=${checkId}`,
      this.authHeaders,
    );

  updateCheck = ({ checkItems, id }) =>
    post(
      `${SERVICE.SCAN_AND_PAY}/updateCheck`,
      {
        checkItems,
        id,
      },
      this.authHeaders,
    );

  payCheck = ({
    id,
    confirmPaymentRequest,
    batchIndex,
    couponInstanceIds = [],
    purchaseEventId,
    tipAmount,
    selectedTipOptionDetails,
  }) =>
    post(
      `${SERVICE.SCAN_AND_PAY}/payCheck`,
      {
        id,
        confirmPaymentRequest,
        batchIndex,
        couponInstanceIds,
        purchaseEventId,
        tipAmount,
        selectedTipOptionDetails,
      },
      this.authHeaders,
    );

  respondToPurchaseEventFromPOS = ({
    approve,
    purchaseEventId,
    couponsBatchIndex,
  }) =>
    approve
      ? post(
          `${SERVICE.POS}/approvePurchaseEvent`,
          {
            os: "web",
            purchaseEventId,
            couponsBatchIndex,
            userId: this.user.userId,
          },
          this.authHeaders,
        )
      : post(
          `${SERVICE.POS}/rejectPurchaseEvent`,
          {
            os: "web",
            purchaseEventId,
            couponsBatchIndex,
            userId: this.user.userId,
          },
          this.authHeaders,
        );

  getCouponBatchesForPurchaseEvent = (purchaseEventId) =>
    get(
      `${
        SERVICE.COUPON
      }/getCouponBatchesForPurchaseEvent/purchaseEventId=${purchaseEventId}`,
      this.authHeaders,
    );

  getOrderStatus = (orderId) =>
    get(`${SERVICE.ORDER}/getOrder/orderId=${orderId}`);
}
