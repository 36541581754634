import classnames from "classnames";
import _ from "lodash";
import React from "react";
import { Img } from "react-image";
import GiftNotificationBadge from "../GiftNotificationBadge";
import EmptyStateIcon from "../icons/ProfileInput.svg";
import * as styles from "./index.module.scss";
export default ({
  avatarUrl,
  style,
  name: nameProp,
  size,
  amountOfGiftsUnseen,
  color,
  appStyles = {},
  user,
}) => {
  const baseFontSize = appStyles.baseFontSize || 16;
  const name = _.get(user, "userDetails.data.name", nameProp);
  const initials =
    name &&
    name
      .trim()
      .split(" ")
      .map((name) => name[0]);

  const getFontSize = () => {
    if (size) {
      if (initials.length === 2) {
        return `${_.round(size / 2) / baseFontSize}rem`;
      } else if (initials.length >= 3) {
        return `${_.round(size / 3) / baseFontSize}rem`;
      }
      return `${size / 1.5 / baseFontSize}rem`;
    } else {
      return false;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {avatarUrl ? (
        <Img
          style={{ ...style, height: size, width: size }}
          src={avatarUrl}
          className={styles.avatar}
        />
      ) : name ? (
        <span
          className={styles.UserInitials}
          style={{
            ...style,
            height: size,
            width: size,
            fontSize: getFontSize(),
            backgroundColor: color || appStyles.headerAvatarBackgroundColor,
            color: appStyles.headerAvatarColor,
          }}
        >
          {_.head(initials) || ""}
          {initials.length > 2 && initials[1]}
          {(initials.length > 1 && _.last(initials)) || ""}
        </span>
      ) : (
        <span
          className={styles.UserInitials}
          style={{
            ...style,
            height: size,
            width: size,
            backgroundColor: appStyles.headerAvatarBackgroundColor,
          }}
        >
          <EmptyStateIcon
            className={styles.NoName}
            style={{ height: size / 2, width: size / 2 }}
          />
        </span>
      )}
      <GiftNotificationBadge
        headerStyle
        appStyles={appStyles}
        amountOfGiftsUnseen={amountOfGiftsUnseen}
        classNames={classnames(
          styles.GiftsNotification,
          appStyles.rtl && styles.RTL,
        )}
      />
    </div>
  );
};
