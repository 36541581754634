module.exports = res => {
  const printError = res =>
    console.error(`Failed to post: URL: ${res.url}, statusText: ${res.statusText}, statusCode ${res.status}`);
  if (!res.ok) {
    printError(res);
    throw Error(`${res.statusText}: ${res.url}`);
  }
  if (res.status === 204) {
    printError(res);
    return Promise.resolve(null);
  }
  return res.json();
};
