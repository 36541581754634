import { filter, get, intersection, isEmpty } from "lodash";

const filterWidgetsByGroups = (widgetsToFilter, user) => {
  if (user && user.loggedIn){
    const groupIds = get(user, "loyaltyProfile.data.groupIds") || [];
    return filter(widgetsToFilter, widget => {
      return (!get(widget, "groups") || 
          isEmpty(get(widget, "groups")) || 
          !isEmpty(intersection(groupIds, get(widget, "groups"))));
    }); 
  }

  return filter(widgetsToFilter, widget => !get(widget, "groups") || isEmpty(get(widget, "groups")));
}

export const getWidgets = (state, props) => {
  const widgets = get(props, "pageContext.widgets");
  const editorWidgets = get(state, "app.editorPromotions");

  const { user } = state;

  const promotionsToFilter = editorWidgets || widgets || []

  return filterWidgetsByGroups(promotionsToFilter, user);
};
