export const NOW = "Now";
export const DAYS_OF_WEEK = 7;
export const MIDNIGHT = 0;
export const OVERNIGHT_SESSION_MIDNIGHT = 24;
export const SET_ICON_BADGE_NUMBER = "SET_ICON_BADGE_NUMBER";
export const USER_DOES_NOT_EXIST = "User doesn't exist";
export const LOCATION_CHANGE = "LOCATION_CHANGE";
export const AVAILABLE_LATER = "AVAILABLE_LATER";
export const AVAILABLE_NOW = "AVAILABLE_NOW";
export const TEMPORARILY_UNAVAILABLE = "TEMPORARILY_UNAVAILABLE";
export const DEFAULT_ERROR = "Something went wrong... please try again";
export const MISSING_HOUSE_NUMBER = "Missing House Number";
export const GEOMETRIC_CENTER = "GEOMETRIC_CENTER";
export const TABLE_CODE = "TABLE_CODE";
export const ADDRESS = "ADDRESS";
export const BRANCH = "BRANCH";
export const SERVING_OPTION_VALUE = "SERVING_OPTION_VALUE";
export const PROMO_CODE = "PROMO_CODE";
export const SEE_MENU_ANYWAY = "SEE_MENU_ANYWAY";
export const CUSTOM_TIP_MODE = "custom";
export const SERVING_OPTION = {
  PICKUP: "pickup",
  DELIVERY: "delivery",
  CATERING: "catering",
  CATERING_DELIVERY: "cateringDelivery",
  SITDOWN: "sitdown",
  DROP_OFF: "dropoff",
  CURBSIDE: "curbside"
};

export const SERVING_OPTION_ORDER = [
  SERVING_OPTION.DELIVERY,
  SERVING_OPTION.PICKUP,
  SERVING_OPTION.SITDOWN,
  SERVING_OPTION.CATERING,
  SERVING_OPTION.CATERING_DELIVERY,
  SERVING_OPTION.DROP_OFF,
  SERVING_OPTION.CURBSIDE
]

export const PAYMENT = {
  NAME: "NAME",
  PHONE_NUMBER: "PHONE_NUMBER",
  EMAIL: "EMAIL",
  PAYMENT_METHOD_INPUT: "PAYMENT_METHOD_INPUT",
};

// CC is for Credit Card
export const CC_FIELDS = {
  NUMBER: "CC_NUMBER",
  EXPIRY_DATE: "CC_EXPIRY_DATE",
  OWNER_ID: "CC_OWNER_ID",
  CVV: "CC_CVV",
  ZIPCODE: "CC_ZIPCODE",
  MONTH: "MONTH",
  YEAR: "YEAR",
  TOKEN: "TOKEN"
};

export const GIFT_CARD_FIELDS = {
  GIFT_CARD_NUMBER: "GIFT_CARD_NUMBER",
  GIFT_CARD_PIN: "GIFT_CARD_PIN",
};

export const HOMEPAGE_TEMPLATE_KEY = {
  default: 'default',
  newsfeed: 'newsfeed',
  loyalty: 'loyalty',
  minimalist: 'minimalist',
};
