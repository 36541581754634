import React from 'react';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import { withPrefix } from 'gatsby';

export default ({ appStyles }) =>
  !isEmpty(appStyles.fontPackages) ? (
    <style
      dangerouslySetInnerHTML={{
        __html: reduce(
          appStyles.fontPackages,
          (html, font) =>
            html +
            `
@font-face {
font-family: ${font.fontFamily};
src: url('${withPrefix(font.src)}');
}`,
          ``
        ),
      }}
    />
  ) : null;
