import classnames from "classnames";
import React from "react";
import WarningIcon from "../icons/Warning.svg";
import * as styles from "./index.module.scss";

export default ({ children, warning, appStyles = {}, refEl }) => (
  <div
    style={{ ...(warning ? appStyles.WarningBox : appStyles.ErrorBox) }}
    className={classnames(
      styles.ErrorMessage,
      warning && styles.WarningMessage,
    )}
    ref={refEl}
  >
    <span>
      <WarningIcon className={styles.ErrorIcon} />
    </span>
    {children}
  </div>
);
