const axios = require("axios");
const get = require("lodash/get");
const returnJsonOrError = require("./returnJsonOrError");

const returnData = (res) => {
  const printError = (res) =>
    console.error(
      `Failed to post: URL: ${get(res, "request.responseURL")}, statusText: ${
        res.statusText
      }, statusCode ${res.status}`,
    );

  if (res.status === 204) {
    printError(res);
  }
  if (res.status > 204) {
    printError(res);
    throw Error(`${res.statusText}: ${get(res, "request.responseURL")}`);
  }

  return res.data;
};

module.exports.get = (url, headers) => {
  const printError = (error) =>
    console.error(
      `GET ${url} Failed to get a response from server, headers: ${
        headers ? headers : "None"
      }, error: ${error}`,
    );
  if (headers) {
    return axios
      .get(url, { headers })
      .then(returnData)
      .catch((error) => {
        printError(error);
        throw error;
      });
  } else {
    return axios
      .get(url)
      .then(returnData)
      .catch((error) => {
        printError(error);
        throw error;
      });
  }
};

module.exports.post = (url, payload, headers) =>
  fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then(returnJsonOrError)
    .catch((error) => {
      console.error(`POST ${url}, Failed, ${error}`);
      throw "Error - failed to fetch";
    });

module.exports.deleteMethod = (url, headers) =>
  fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    method: "DELETE",
  })
    .then(returnJsonOrError)
    .catch((error) => {
      console.error(`DELETE ${url}, Failed, ${error}`);
      throw "Error - failed to fetch";
    });
