import * as ACTION_TYPE from "./constants";

export const setMenuItemConfiguration = (id, menuItemConfiguration) => (
  dispatch,
  getState,
) =>
  dispatch({
    type: ACTION_TYPE.SET_MENU_ITEM_CONFIGURATION,
    payload: {
      id,
      menuItemConfiguration,
      userId: getState().user.userId || "common",
    },
  });

export const setOrderItemComponent = (
  id,
  { title, chosenOrderItemComponentOptions },
) => (dispatch, getState) =>
  dispatch({
    type: ACTION_TYPE.SET_MENU_ITEM_COMPONENT,
    payload: {
      id,
      title,
      chosenOrderItemComponentOptions,
      userId: getState().user.userId || "common",
    },
  });

export const setOrderItemTopping = (
  id,
  { categoryTitle, toppingTitle, weight, position, multiplierId },
) => (dispatch, getState) =>
  dispatch({
    type: ACTION_TYPE.SET_MENU_ITEM_TOPPING,
    payload: {
      id,
      categoryTitle,
      toppingTitle,
      weight,
      position,
      multiplierId,
      chosenTime: new Date().getTime(),
      userId: getState().user.userId || "common",
    },
  });

export const setSize = (id, { selectedMenuSizeId }) => (dispatch, getState) =>
  dispatch({
    type: ACTION_TYPE.SET_MENU_ITEM_SIZE,
    payload: {
      id,
      selectedMenuSizeId,
      userId: getState().user.userId || "common",
    },
  });

export const resetMenuItemConfiguration = (id, initialConfiguration) => (
  dispatch,
  getState,
) =>
  dispatch({
    type: ACTION_TYPE.RESET_MENU_ITEM_CONFIGURATION,
    payload: {
      id,
      userId: getState().user.userId || "common",
      initialConfiguration,
    },
  });

export const transferMenuItemsConfigurationToUser = () => (
  dispatch,
  getState,
) =>
  dispatch({
    type: ACTION_TYPE.TRANSFER_MENU_CONFIGURATION,
    payload: { userId: getState().user.userId },
  });

export const setMenuScrollPosition = (position) => ({
  type: ACTION_TYPE.SET_MENU_SCROLL_POSITION,
  payload: { position },
});
