export const ZOOZ_SCRIPT =
  process.env.GATSBY_APP_ENV === "production"
    ? "https://app.zooz.com/mobile/checkoutapi/js/checkout-ext-api.js"
    : "https://sandbox.zooz.co/mobile/checkoutapi/js/checkout-ext-api.js";

export default class ZoozAPI {
  constructor(programId) {
    this.initParams = {
      isSandbox: process.env.GATSBY_APP_ENV != "production",
      programId,
    };
    this.api = new window.Zooz.Ext.External(this.initParams);
  }
  init = () =>
    new Promise((resolve, reject) =>
      this.api.init(this.initParams, resolve, reject),
    );

  addPaymentMethod = (paymentRequest) =>
    new Promise((resolve, reject) =>
      this.api.addPaymentMethod(paymentRequest, resolve, reject),
    );

  removePaymentMethod = (removePaymentMethodRequest) =>
    new Promise((resolve, reject) =>
      this.api.removePaymentMethod(removePaymentMethodRequest, resolve, reject),
    );
}
