import _ from 'lodash';

export default cardNumber => {
  if (/^\d{8,9}$/.test(cardNumber)) {
    const effectiveCardNumber = cardNumber.length === 8 ? '0' + cardNumber : cardNumber;
    const cardSum = _.sum(_.map(effectiveCardNumber, (digit, index) => (cardNumber.length - index) * digit));

    const isValid = cardSum % 11 === 0;

    return isValid;
  }
  return false;
};
