import React from "react";
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";
import _, {
  get,
  compact,
  startsWith,
  map,
  includes,
  isEmpty,
  find,
  filter,
  uniqBy,
  split
} from "lodash";
import {
  getAwHeadScript,
  getGtmHeadScript,
  initGtagScript,
  gtagDataLayerInitScript,
} from "../utils/gtm";
import { getAppMediaSrc, getSrcSet, mediaTypes } from "../utils/media";
import {HOMEPAGE_TEMPLATE_KEY} from "../utils/constants";
import {isNewsfeedTemplateLayout} from "../logic/templates/isNewsfeedTemplateLayout";
import { getParams } from "../utils/location";

export default ({ app, T, location, appStyles, business, branches }) => {
  const { trackings, links = {}, desktopBackgroundImage = {} } = business;
  const usingNewsfeedTemplateLayout = isNewsfeedTemplateLayout(appStyles);
  const isFullscreen =
    !(
      app.mobileAuthViewOpen ||
      app.authModalOpen ||
      usingNewsfeedTemplateLayout
    ) && location.pathname === "/";

  const height = isFullscreen ? "100%" : "auto";
  const overflow = isFullscreen ? "hidden" : "auto";
  const position = isFullscreen ? "fixed" : "relative";
  const letterSpacing = appStyles.letterSpacing;
  const background = appStyles.backgroundImage
    ? `url(${withPrefix(appStyles.backgroundImage)})`
    : appStyles.background;
  const fontSize = appStyles.baseFontSize || 16;

  const HTMLStyleAttribute = app.isSSR
    ? { height, background, fontSize, overflow, position, letterSpacing }
    : compact([
        `height: ${height};`,
        `font-size: ${fontSize}px;`,
        `overflow: ${overflow};`,
        `position: ${position};`,
        letterSpacing && `letter-spacing: ${letterSpacing};`,
        background && `background: ${background};`,
      ]).join("");

  const googleFontLink = toGoogleFontLink(appStyles.googleFonts);
  const facebookMetaTagId = getFacebookMetaTagId(trackings);
  const trackingIds = map(
    uniqBy(filter(trackings, { type: "gtm" }), "trackingId"),
    "trackingId",
  );
  const awIds = filter(trackingIds, (id) => startsWith(id, "AW"));
  const gtmIds = filter(trackingIds, (id) => startsWith(id, "GTM"));

  const appStoreId = _.get(links, "appStoreAppId");

  const { branchId, branchName } = getParams(location);

  const isBranchSelected = Boolean(branchId && branchName);

  const branch = branches.find(({id}) => id === branchId);
  
  const businessNameContent =
    isBranchSelected && branch
      ? `{{business_name}} | ${branchName}`
      : "{{business_name}}";

  const imageData =
    isBranchSelected && branch
      ? split(getSrcSet(mediaTypes.branch, branch.imageKey, true), " ")[0]
      : desktopBackgroundImage.url;

  const title = branchName
    ? T("{{business_title}}", { locationName: branchName })
    : T(`${businessNameContent} - Just Order`);

  const description = branchName
    ? T("{{business_description}}", { locationName: branchName })
    : T(`${businessNameContent} - Just Order`);

  return (
    <Helmet
      defer={false}
      title={title}
      meta={compact([
        {
          name: "description",
          content: description,
        },
        {
          name: "keywords",
          content: `${T("{{business_name}}")}, ${T("food")}`,
        },
        {
          name: "viewport",
          content: "initial-scale=1.0, width=device-width",
        },
        appStoreId
          ? {
              name: "apple-itunes-app",
              content: `app-id=${appStoreId}`
            }
          : null,
        facebookMetaTagId
          ? {
              name: "facebook-domain-verification",
              content: facebookMetaTagId,
            }
          : null,
        {
          name: "robots",
          content: "index, follow",
        },
        // Open Graph Tags
        {
          property: "og:site_name",
          content: T(businessNameContent),
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: "og:type",
          content: "restaurant.restaurant",
        },
        {
          property: "og:image",
          content: imageData,
        },
        {
          property: "og:url",
          content: links.webApp,
        },
        // Twitter Cards
        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:site",
          content: links.webApp,
        },
        {
          property: "twitter:title",
          content: title,
        },
        {
          property: "twitter:description",
          content: description,
        },
        {
          property: "twitter:image",
          content: imageData,
        }
      ])}
      link={compact([
        {
          rel: "icon",
          sizes: "64x64",
          type: "image/png",
          href: getAppMediaSrc(mediaTypes.appIcon, appStyles),
        },
        {
          rel: "icon",
          sizes: "48x48",
          type: "image/png",
          href: getAppMediaSrc(mediaTypes.appIcon, appStyles, 48)
        },
        googleFontLink && {
          rel: "stylesheet",
          href: googleFontLink,
        },
        {
          rel: "canonical",
          href: links.webApp
        }
      ])}
    >
      {map(awIds, (awId) => (
        <script async src={getAwHeadScript(awId)} />
      ))}
      <script>{gtagDataLayerInitScript}</script>
      <script>dataLayer=window.dataLayer || [];</script>
      {map(gtmIds, (gtmId) => (
        <script>{getGtmHeadScript(gtmId)}</script>
      ))}
      {!isEmpty(awIds) && <script>{initGtagScript(awIds)}</script>}
      {branch && generateSchemaMarkup(business, branch)}
      <html style={HTMLStyleAttribute} />
      {_.get(appStyles, "hideRecaptchaBadge") && <style type="text/css">
        {`.grecaptcha-badge { 
          visibility: hidden;
        }`}
      </style>}
    </Helmet>
  );
};

const googleFontBaseUrl = "https://fonts.googleapis.com/css2";

const toGoogleFontLink = (googleFonts) => {
  if (isEmpty(googleFonts)) {
    return null;
  }
  const googleFontQueryString = map(googleFonts, (googleFont) => {
    const [family, weights = ""] = googleFont.split(":");
    const hasItalics = includes(weights, "i");
    const hasWeights = Boolean(weights.length);
    const fontQS = `family=${family}${hasItalics || hasWeights ? ":" : ""}${
      hasItalics ? "ital" : ""
    }${hasItalics && hasWeights ? "," : ""}${hasWeights ? "wght@" : ""}${map(
      weights.split(","),
      (weight) => {
        return `${
          hasItalics ? (includes(weight, "i") ? "1," : "0,") : ""
        }${parseInt(weight, 10)}`;
      },
    ).join(";")}`;
    return fontQS;
  }).join("&");
  return `${googleFontBaseUrl}?${googleFontQueryString}`;
};

const getFacebookMetaTagId = (trackings) => {
  const fbTracking = find(trackings, { type: "facebook" });
  if (fbTracking) {
    const metaTagId = get(
      find(fbTracking.events, {
        type: "MetaTagVerification",
      }),
      "params.metaTag",
    );
    return metaTagId;
  }
};

const generateSchemaMarkup = (business, branch) => {
  const businessNameContent =  branch ? `${business.name} | ${branch.name}` : business.name;

  const schema = {
    "@context": "http://schema.org",
    "@type": "Restaurant",
    "name": businessNameContent,
    "address": {
      "@type": "PostalAddress",
      "streetAddress": get(branch, "deliveryAddress.street"),
      "addressLocality": get(branch, "deliveryAddress.city"),
      "addressRegion": get(branch, "deliveryAddress.state"),
      "postalCode": get(branch, "deliveryAddress.zipCode"),
      "addressCountry": "USA"
    },
    "openingHours": [branch.openHours],
    "menu": `${business.links.webApp}order/?branchId=${branch.id}&branchName=${branch.name}&search=/`,
    "servesCuisine": get(business, "cuisineType"),
    "priceRange": get(business, "priceRange"),
    "review": [
      // {
      //   "@type": "Review",
      //   "author": "Customer Name",
      //   "datePublished": "2024-03-03",
      //   "reviewBody": "A wonderful dining experience at Your Restaurant Name. The food was delicious, and the service was excellent."
      // }
    ]
  }

  return (
    <script type="application/ld+json">
      {JSON.stringify(schema)}
    </script>
  );
}
