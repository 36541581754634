import React from "react";
import openNativeLink from "../../utils/openNativeLink";
import { Link } from "../../utils/location";

export default ({
  children,
  style,
  href,
  link: linkUrl,
  fallbackLink,
  appStyles = {},
  title,
  notBlank,
  className,
}) => {
  const nativeMode = typeof window !== "undefined" ? window.isNativeApp : false;
  const link = href || linkUrl;
  return link ? (
    <a
      href={nativeMode ? "#" : link}
      onClick={nativeMode && openNativeLink(link)}
      target={!nativeMode && !notBlank && "_blank" || undefined}
      title={title}
      style={{ color: appStyles.textColor, ...style }}
      className={className}
    >
      {children}
    </a>
  ) : (
    <Link
      style={{ color: appStyles.textColor, ...style }}
      to={fallbackLink}
      className={className}
    >
      {children}
    </Link>
  );
};
