import React from "react";
import castArray from "lodash/castArray";
import templateSettings from "lodash/templateSettings";
import template from "lodash/template";
import compact from "lodash/compact";
import get from "lodash/get";
import { withNamespaces } from "react-i18next";
import { flowRight } from "lodash";

templateSettings.interpolate = /%%([\s\S]+?)%%/g;

const interpolate = (message, dictionary) => {
  return template(message)(dictionary);
};

const withHandlers = () => Component => ({ ...props }) => {
  const T = (toTranslate, varsDictionay) => 
        props.tReady
          ? interpolate(
              props.t(
                toTranslate,
                props.pageContext.interpolationTemplates || [],
              ),
              varsDictionay,
            )
          : interpolate(toTranslate, varsDictionay);

  return <Component {...props} T={T} />
};

export default flowRight(
  withNamespaces((props) => compact([
      ...castArray(props.namespaces),
      ...castArray(get(props.pageContext, "namespaces")),
    ]),
  ),
  withHandlers()
);
