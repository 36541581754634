import _ from "lodash";
import { LOCATION_CHANGE } from "../../utils/constants";

const initialState = {
  showTabs: false,
  activeTab: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      const pathname =
        action.payload.pathname === "/"
          ? action.payload.pathname
          : _.endsWith(action.payload.pathname, "/")
          ? action.payload.pathname.slice(0, -1)
          : action.payload.pathname;
      switch (pathname) {
        case "/":
          return {
            ...state,
            activeTab: 0,
            showTabs: true,
          };
        case "/gifts":
          return {
            ...state,
            activeTab: 1,
            showTabs: true,
          };
        case "/find-location":
        case "/serving-options":
          return {
            ...state,
            activeTab: 2,
            showTabs: true,
          };
        case "/order":
          return {
            ...state,
            activeTab: 2,
            showTabs: true,
          };
        case "/scan":
          return {
            ...state,
            activeTab: 3,
            showTabs: true,
          };

        default:
          return {
            ...state,
            showTabs: false,
          };
      }
    default:
      return state;
  }
};
