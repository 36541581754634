import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";
const defaults = require("../../static/defaults.json");

// const NAMESPACES = ["translation", "About", "Privacy", "TOS"];
console.log("*********************************************");
console.log("Default Language:", defaults.defaultLanguage);
console.log("*********************************************");

const options = {
  fallbackLng: defaults.defaultLanguage,
  lng: defaults.defaultLanguage,
  lowerCaseLng: true,
  load: "currentOnly",
  // have a common namespace used around the full app
  ns: "translation",
  defaultNS: "translation",

  debug: false,

  interpolation: { escapeValue: false }, // not needed for react

  react: { wait: true },
  keySeparator: "~", // to separate keys in translation
  nsSeparator: false,
};

i18n.init(options);

export default i18n.use(LanguageDetector).use(reactI18nextModule);
