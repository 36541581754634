import _, { get } from "lodash";
import { getParams, setParams, removeParams } from "../utils/location";

export default (
  location,
  pageContext,
  {
    user = {},
    order = {},
    giftRedeem = {},
    mobileAuthViewOpen,
    checkoutOpenInMobile,
    closeCheckoutView,
    openCheckoutView,
    kioskLocationId,
    groupReferralUrl,
    appStyles,
    isMobile
  },
) => {
  const { servingOptions } = pageContext;
  const nativeMode = typeof window !== "undefined" ? window.isNativeApp : false;
  const params = getParams(location);
  const pathname = location.pathname;
  const trimmedPathname = _.endsWith(pathname, "/")
    ? _.trimEnd(pathname, "/")
    : pathname;

  if (mobileAuthViewOpen) {
    return {
      headerTitle: user.showVerifyView
        ? "Verification Code"
        : user.authMode === "signup"
        ? "Signup"
        : "Login",
      showOnlyClose: true,
    };
  }

  if (params.rechargeCard) {
    return {
      headerTitle: "Recharge Card",
      backPath: removeParams(location, "rechargeCard"),
    };
  }

  if (
    _.startsWith(trimmedPathname, "/deals") &&
    giftRedeem.isDuringDealRedeem
  ) {
    const currentStep = giftRedeem.steps[giftRedeem.currentStepIndex];

    return {
      onClosePath: "/order",
      showOnlyClose: true,
      backPath:
        giftRedeem.currentStepIndex < 1
          ? "/order"
          : setParams(
              giftRedeem.steps[giftRedeem.currentStepIndex - 1].context
                .pathname,
              { stepIndex: giftRedeem.currentStepIndex - 1 },
            ),
    };
  }

  if (_.startsWith(trimmedPathname, "/order/items")) {
    if (params.editMode) {
      return {
        headerTitle: getParams(location).itemName,
        showOnlyClose: true,
        noShadow: appStyles.menuItemImageFocusStyle,
        onClosePath: setParams("/order", _.omit(params, "editMode")),
      };
    }

    if (giftRedeem.isDuringGiftRedeem) {
      const currentStep = giftRedeem.steps[giftRedeem.currentStepIndex];

      return {
        onClosePath: setParams("/order", params),
        showOnlyClose: true,
        headerTitle: "Reward",
        noShadow: appStyles.menuItemImageFocusStyle,
        backPath:
          giftRedeem.currentStepIndex < 1
            ? "/gifts"
            : setParams(
                giftRedeem.steps[giftRedeem.currentStepIndex - 1].context
                  .pathname,
                { stepIndex: giftRedeem.currentStepIndex - 1 },
              ),
      };
    }
    if (giftRedeem.isDuringDealRedeem) {
      const currentStep = giftRedeem.steps[giftRedeem.currentStepIndex];

      return {
        onClosePath: setParams("/order", params),
        showOnlyClose: true,

        noShadow: appStyles.menuItemImageFocusStyle,
        backPath:
          giftRedeem.currentStepIndex < 1
            ? setParams("/order", params)
            : setParams(
                giftRedeem.steps[giftRedeem.currentStepIndex - 1].context
                  .pathname,
                { stepIndex: giftRedeem.currentStepIndex - 1 },
              ),
      };
    }
    return {
      noShadow: appStyles.menuItemImageFocusStyle,
      headerTitle: params.itemName,
      showOnlyClose: true,
      onClosePath: setParams("/order", params),
    };
  }

  switch (trimmedPathname) {
    case "/login":
      return {
        headerTitle: "Login",
        showOnlyClose: true,
        onClosePath: "/",
      };
    case "/signup":
      return {
        headerTitle: user.showVerifyView
          ? "Verification Code"
          : user.authMode === "signup"
          ? "Signup"
          : "Login",
        showOnlyClose: true,
        onClosePath: "/",
      };
    case "/verifyLoginCode":
      return {
        headerTitle: "Verification Code",
        showOnlyClose: true,
      };
    case "/my-account":
      return {
        showLogo: true,
        headerTitle: "My Account",
        showOnlyClose: true,
      };
    case "/prepaid":
      return {
        headerTitle: "Prepaid Card",
        backPath: params.backPath
          ? setParams(params.backPath, _.omit(params, "backPath"))
          : "/",
      };
    case "/serving-options":
      return {
        headerTitle: "Choose Serving Option",
        backPath: giftRedeem.selectedGift ? "/gifts" : "/",
      };
    case "/find-location":
      const shouldSkipServingOptionPage = get(pageContext, "business.appStyles.shouldChooseServingOptionFromModal") ||
        get(pageContext, "business.appStyles.skipServingOptionPage");
      return {
        headerTitle: "Find Location",
        backPath: giftRedeem.selectedGift
          ? "/serving-options"
          : servingOptions.length < 2 || shouldSkipServingOptionPage
          ? "/"
          : "/serving-options",
      };
    case "/tos":
      return {
        headerTitle: "Terms of Service",
        backPath: setParams(
          params.backPath ? params.backPath : "/",
          _.omit(params, "backPath"),
        ),
      };
    case "/gifts":
      return {
        // headerTitle: "Rewards",
        backPath: removeParams(
          setParams(
            _.isEmpty(params.backPath) ? "/" : params.backPath,
            _.omit(params, "backPath"),
          ),
          "stepIndex",
        ),
        showLogo: true,
      };
    case "/about":
      return {
        headerTitle: "About",
        backPath: setParams(
          params.backPath ? params.backPath : "/",
          _.omit(params, "backPath"),
        ),
      };
    case "/privacy-policy":
      return {
        headerTitle: "Privacy Policy",
        backPath: setParams(
          params.backPath ? params.backPath : "/",
          _.omit(params, "backPath"),
        ),
      };
    case "/reorder":
      return {
        headerTitle: "Reorder",
        backPath: "/",
      };
    case "/history":
      return {
        headerTitle: "Order History",
        backPath: setParams("/my-account", params),
      };
    case "/order":
      const branch = _.find(pageContext.branches, { id: order.branchId });
      if (kioskLocationId) {
        return {
          headerTitle: "Order Details",
          backPath: `/kiosk/${kioskLocationId}`,
        };
      }

      const isCategoryPageEnabled = _.get(appStyles, "categoriesPageConfig.enableCategoriesPage");

      const backPath =
        isMobile &&
        isCategoryPageEnabled
          ? "/categories"
          : "/find-location";

      return checkoutOpenInMobile
        ? {
            headerTitle: "Order Details",
            backFunction: closeCheckoutView,
            ...(isCategoryPageEnabled && {
              backPath: setParams(
                backPath,
                _.pick(params, ["branchName", "branchId", "servingOptionType"])
              ),
            }),
          }
        : {
            headerTitle: branch ? branch.name : "",
            ...(groupReferralUrl
              ? {
                  backFunction: () =>
                    (window.location.href = groupReferralUrl),
                }
              : {
                  backPath: setParams(
                    backPath,
                    _.pick(params, ["branchName", "branchId", "servingOptionType"])
                  ),
                }),
          };
    case "/categories":
      return { headerTitle: "Categories", backPath: setParams("/find-location", params) };
    case "/payment":
      if (order.placingOrder) {
        return { showLogo: true, headerTitle: "Placing Order" };
      }
      if (order.orderPlaced) {
        return { showLogo: true, showOnlyClose: true, onClosePath: "/" };
      }
      return { headerTitle: "Checkout", backFunction: openCheckoutView };
    case "/gift":
      return {
        headerTitle: "Reward",
        backPath: setParams("/gifts", _.omit(getParams(location), "giftId")),
      };

    case "/profile":
      if (params.changePhoto) {
        return {
          headerTitle: "Change Photo",
          backPath: removeParams(`/profile${location.search}`, "changePhoto"),
        };
      }
      return {
        headerTitle: "My Account",
        backPath: setParams("/my-account", params),
      };

    case "/charities":
      return {
        headerTitle: "Donate Reward",
        backPath: setParams("/gifts", params),
      };

    case "/donate":
      return {
        headerTitle: "Donate Reward",
        backPath: "/",
      };

    case "/scan":
      return {
        headerTitle: "Scan",
        backPath: "/",
      };
    case "/contacts":
      return {
        headerTitle: params.fromGiftCard ? "Send a Gift Card" : "Share Reward",
        backPath: params.fromGiftCard
          ? setParams("/my-account", _.omit(params, "fromGiftCard"))
          : "/gifts",
      };

    case "/share":
      return {
        headerTitle: "Share Reward",
        backPath: user.shareGiftState.shareGiftDesktop ? "/gifts" : "/contacts",
      };

    case "/gift-thanks":
      return { headerTitle: "Share Reward" };
    case "/payment-methods":
      return {
        headerTitle: "Payment Methods",
        backPath: setParams(
          params.backPath ? params.backPath : "/my-account",
          _.omit(params, "backPath"),
        ),
      };
    case "/contact-us":
      return {
        headerTitle: "Contact Us",
        backPath: params.onCloseTarget ? setParams("/my-account", params) : "/",
      };
    case "/purchase-event":
      return { headerTitle: "Payment" };
    case "/kiosk-pay":
      return {
        headerTitle: "Payment",
        backPath: setParams("/order", params),
      };
    case "/in-store-scan":
      return { headerTitle: "Scan", showLogo: true };
    case "/gift-card":
      if (!user.loggedIn) {
        return {
          headerTitle: "Gift Card",
          backPath: "/",
        };
      }

      return {
        headerTitle: "Gift Card",
        backPath: "/",
      };
    default:
      return {
        headerTitle: null,
        backPath: null,
      };
  }
};
