import React from "react";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { useTheme as themeCreator } from "@mui/material";
import { createMakeAndWithStyles } from "tss-react";
import { createMuiCache } from "./theme-cache";
import { breakpoints } from "../../styles";

export const { makeStyles } = createMakeAndWithStyles({
  useTheme: themeCreator,
});

export default function MuiTheme({ appStyles = {}, children }) {
  const theme = useTheme(appStyles);
  const muiCache = createMuiCache(appStyles);

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CacheProvider>
  );
}

const useTheme = (appStyles) => {
  const theme = createTheme({
    direction: appStyles.rtl ? "rtl" : "ltr",
    typography: {
      fontFamily: [
        appStyles.bodyFont,
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    breakpoints,
    button: {
      fontFamily: appStyles.Button?.fontFamily || appStyles.bodyFont,
    },

    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontFamily:
              appStyles.AddressInput?.fontFamily ||
              appStyles.Input?.fontFamily,
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          primary: {
            ...appStyles.Button,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: appStyles.backgroundColor,
            color: appStyles.textColor,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            ...(appStyles.cardBackgroundColor && {
              background: appStyles.cardBackgroundColor,
            }),
            ...appStyles.Card,
          },
        },
      },

      MuiCardHeader: {
        styleOverrides: {
          root: {
            ...appStyles.CardTitle,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            ...appStyles.Card,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          colorTextSecondary: {
            color: appStyles.accentColor,
          },
          colorTextPrimary: {
            color: appStyles.actionColor,
          },
          body1 :{
            color: appStyles.actionColor,
          }
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: appStyles.actionColor,
              color: appStyles.textColor,
            },
          },
        },
      },
    },

    ...(appStyles.actionColor &&
      appStyles.accentColor && {
        palette: {
          background: {
            paper: appStyles.backgroundColor,
          },
          primary: {
            main: appStyles.actionColor,
            light: appStyles.actionColor,
            dark: appStyles.actionColor,
          },
          secondary: {
            main: appStyles.accentColor,
            light: appStyles.accentColor,
            dark: appStyles.accentColor,
          },
        },
      }),
  });

  theme.typography.h2 = {
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.25rem",
    },
  };
  return theme;
};
