import includes from "lodash/includes";

export const ORDER_STATE = {
  DONE: "DONE", // Done successfully
  CANCELED: "CANCELED", // Failed on POS
  PROCESSING: "PROCESSING", // Should check again soon
  UNKNOWN: "UNKNOWN",
};

// 3, 4, 5, 6, 7
export const isOrderDone = (checkoutResponse = {}) =>
  checkoutResponse.status > 2 && checkoutResponse.status < 8;

// 8, 9, 10, 0
export const isOrderCanceled = (checkoutResponse = {}) =>
  (checkoutResponse.status > 7 && checkoutResponse.status < 11) ||
  checkoutResponse.status === 0;

// 1, 2, 11 or null checkout response
export const isOrderProcessing = checkoutResponse =>
  !checkoutResponse || includes([1, 2, 11], checkoutResponse.status);

export const getOrderState = checkoutResponse =>
  isOrderDone(checkoutResponse)
    ? ORDER_STATE.DONE
    : isOrderCanceled(checkoutResponse)
    ? ORDER_STATE.CANCELED
    : isOrderProcessing(checkoutResponse)
    ? ORDER_STATE.PROCESSING
    : ORDER_STATE.UNKNOWN;
