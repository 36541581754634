import { get } from "lodash";

export const getAppStyles = (state, props) => {
  const appStyles = get(props, "pageContext.business.appStyles");
  const editorAppStyles = get(state, "app.editorAppStyles");

  return (
    editorAppStyles || {
      ...appStyles,
    } ||
    {}
  );
};

// {
//   collapseModifiersByDefault: true,
//   expandModifiersIfLessThan: 0,
//   expandToppingsIfLessThan: 8,
// }
