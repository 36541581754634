import { Box, Typography } from "@mui/material";
import { PersonPinCircleOutlined } from "@mui/icons-material";
import React, { useMemo, useState } from "react";
import SelectInput from "../Inputs/Select";

function PreferredLocations({
  availableLocations,
  errorMessage,
  required,
  T,
  label,
  style,
  onChange,
  ...props
}) {
  const locationOptions = useMemo(
    () =>
      availableLocations.map(({ name, id }) => ({ label: name, value: id })),
    [availableLocations]
  );

  const [open, setOpen] = useState(false);

  return (
    <Box sx={style}>
      {label || (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
          <Typography variant="subtitle1">
            {T(`Preferred locations ${!require ? "(optional)" : ""}`)}
          </Typography>
        </div>
      )}
      <SelectInput
        {...props}
        onChange={(e) => {
          onChange(e);
          setOpen(false);
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={locationOptions}
        customIconAdornment={
          <PersonPinCircleOutlined
            style={{ paddingRight: 8, color: "#757575" }}
          />
        }
        placeholder={T("Please select preferred locations")}
        multiple
        isError={Boolean(errorMessage)}
        errorMessage={errorMessage}
        open={open}
      />
    </Box>
  );
}

export default PreferredLocations;
