import _ from 'lodash';
import * as ACTION_TYPE from './constants';

const initialState = {
	loading: false,
	loaded: false,
	google: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPE.GEOCODE_LOCATION.STARTED:
			return { ...state, loading: true, google: action.payload.google };
		case ACTION_TYPE.GEOCODE_LOCATION.SUCCESS:
			return { ...state, ...action.payload, loaded: true, loading: false };
		case ACTION_TYPE.GEOCODE_LOCATION.FAILED:
			return { ...state, loading: false, loaded: false };
		case ACTION_TYPE.GEOCODE_LOCATION.SET:
			return { ...state, ...action.payload, loaded: true, loading: false };
		default:
			return state;
	}
};
