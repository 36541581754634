// extracted by mini-css-extract-plugin
export var CheckoutHeaderBugButton = "CheckoutSideBar-module--CheckoutHeaderBugButton--5b96c";
export var CheckoutHeaderCloseButton = "CheckoutSideBar-module--CheckoutHeaderCloseButton--b13fd";
export var CheckoutHeaderTitle = "CheckoutSideBar-module--CheckoutHeaderTitle--fb901";
export var CheckoutPanelHeader = "CheckoutSideBar-module--CheckoutPanelHeader--77124";
export var CheckoutSideBar = "CheckoutSideBar-module--CheckoutSideBar--0d3cd";
export var Open = "CheckoutSideBar-module--Open--4b553";
export var RTL = "CheckoutSideBar-module--RTL--63197";
export var fadeAndGrowIn = "CheckoutSideBar-module--fadeAndGrowIn--b232f";
export var fromBottom = "CheckoutSideBar-module--fromBottom--3ab3d";
export var fromLeft = "CheckoutSideBar-module--fromLeft--caf13";
export var fromRight = "CheckoutSideBar-module--fromRight--0a849";